<template>
  <div class="products">
    <div class="products-header">
      <a-row>
        <a-col :xs="24" :sm="12" :md="10" :lg="8" :xl="6" class="products-filters">
          <h3 class="card-title">Filtrer les articles</h3>
          <a-checkbox @change="showFavorite">
            ⭐ Favoris ({{ nbFavorites }})
          </a-checkbox>
          <a-checkbox-group class="products-filters-list" v-if="options" v-model="checkedList" :options="options"
            @change="onChange" />
        </a-col>
      </a-row>
      <a-row type="flex" justify="space-around" align="middle">
        <a-col :xs="0" :sm="0" :md="8" :lg="8" :xl="8"></a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <a-input-search class="products-search" placeholder="Saisissez votre recherche" @change="onSearch"
            v-model="searchInputValue" />
          <div v-if="searchResults !== null" class="products-search-result">
            <span>
              <!-- {{ searchResults }}  -->
              {{ commentResult }}
            </span>
          </div>
          <div v-if="searchInputValue == ''" class="products-search-result"></div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <div v-if="showPrice" class="downloadLink">
            <a-button type="primary" block icon="download" @click="getCSVProductsList">
              Télécharger la liste des produits</a-button>
          </div>
          <div class="products-search-result"></div>
        </a-col>
      </a-row>
    </div>
    <a-spin class="spin-class" tip="Chargement..." size="large" :spinning="visibleSpin">
      <!-- <a-tag v-if="!favoriteIsSelected" color="orange"> orange </a-tag> -->
      <a-table class="products-datatable" :scroll="{ y: 500 }" rowKey="id" :columns="columns" :data-source="articles"
        bordered :locale="{ emptyText: 'Aucun article disponible' }">
        <span class="products-datatable-gamme" slot="gamme" slot-scope="gamme"
          :style="[{ borderBottom: '5px solid ' + gamme.color }, gammeTdClass]">
          {{ gamme.lib }}
        </span>
        <span class="products-datatable-lib" slot="lib" slot-scope="text" v-html="text">
        </span>
        <span class="products-datatable-stock" slot="stock" slot-scope="text" v-html="text"></span>
        <span v-if="showPrice" class="products-datatable-price" slot="price" slot-scope="text" v-html="text"></span>
        <span class="products-datatable-action" slot="action" slot-scope="record">
          <a-button type="primary" @click="showModal(record)">
            <i aria-label="icon: shopping-cart" class="anticon anticon-shopping-cart">
              <svg viewBox="0 0 1024 1024" data-icon="shopping-cart" width="20px" height="25px" fill="currentColor"
                aria-hidden="true" focusable="false" class="">
                <path
                  d="M922.9 701.9H327.4l29.9-60.9 496.8-.9c16.8 0 31.2-12 34.2-28.6l68.8-385.1c1.8-10.1-.9-20.5-7.5-28.4a34.99 34.99 0 0 0-26.6-12.5l-632-2.1-5.4-25.4c-3.4-16.2-18-28-34.6-28H96.5a35.3 35.3 0 1 0 0 70.6h125.9L246 312.8l58.1 281.3-74.8 122.1a34.96 34.96 0 0 0-3 36.8c6 11.9 18.1 19.4 31.5 19.4h62.8a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7h161.1a102.43 102.43 0 0 0-20.6 61.7c0 56.6 46 102.6 102.6 102.6s102.6-46 102.6-102.6c0-22.3-7.4-44-20.6-61.7H923c19.4 0 35.3-15.8 35.3-35.3a35.42 35.42 0 0 0-35.4-35.2zM305.7 253l575.8 1.9-56.4 315.8-452.3.8L305.7 253zm96.9 612.7c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6zm325.1 0c-17.4 0-31.6-14.2-31.6-31.6 0-17.4 14.2-31.6 31.6-31.6s31.6 14.2 31.6 31.6a31.6 31.6 0 0 1-31.6 31.6z">
                </path>
              </svg>
            </i>
          </a-button>
        </span>
      </a-table>
      <div class="products-display-price">
        <!-- <a-checkbox v-model="showPrice" @change="changePriceVisibility()"> -->
        <a-checkbox :v-model="!showPrice" @change="changePriceVisibility()">
          Masquer les tarifs
        </a-checkbox>
      </div>
    </a-spin>
    <!-- modal d'ajout au panier -->
    <a-modal class="products-modal" centered v-if="visibleModal" v-model="visibleModal" :footer="null"
      @cancel="closeModal">
      <h2 class="products-modal-gamme" :style="[
        { borderBottom: '5px solid ' + article.color, margin: '15px auto' },
      ]">
        {{ article.gamme.lib.toUpperCase() }}
      </h2>
      <h3 class="products-modal-lib" v-html="article.lib"></h3>
      <div v-if="showPrice" class="products-modal-price">
        <div class="products-modal-tranche">
          <h4 style="align-self: center; margin-bottom: 5px">TARIF :</h4>
          <span v-if="article.remise.TRANCHE.length === 0">Prix unitaire : {{ article.puRemise }} €</span>
        </div>
        <div class="products-modal-sell">
          <a-input-number id="inputNumber" v-model="productQuantity" :min="1" :max="1000"
            @change="InputQuantityChange(article)" />
          <span v-if="showPrice" class="products-modal-total">
            Total : {{ totalProduct.toFixed(2) }} €
          </span>
          <span v-if="showPrice" class="products-modal-taxe">
            + écotaxe : {{ totalEcoTaxe.toFixed(2) }} €
          </span>
        </div>
      </div>
      <div v-else class="products-modal-alt-mode">
        <h4 style="margin-right: 5px;">QUANTITÉ :</h4>
        <a-input-number style="margin-left: 5px;" id="inputNumber" v-model="productQuantity" :min="1" :max="1000"
          @change="InputQuantityChange(article)" />
      </div>
      <div class="products-modal-comm">
        <a-textarea placeholder="Ajouter un commentaire (facultatif) 
  Exemple: CM: Dupont" :maxLength="30" :rows="2" v-model="comArticle" />
        {{ comArticle.length }} / 30
        <a-button type="primary" @click="addCart(article, productQuantity)">
          Ajouter au panier
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "Gamme",
    dataIndex: "gamme",
    key: "gamme",
    scopedSlots: { customRender: "gamme" },
    sorter: (a, b) => a.gamme.lib.localeCompare(b.gamme.lib),
    sortDirections: ["descend", "ascend"],
    width: "100px",
    align: "center",
  },
  {
    title: "Libellés",
    dataIndex: "lib",
    key: "lib",
    scopedSlots: { customRender: "lib" },
    sorter: (a, b) => a.lib.localeCompare(b.lib),
    sortDirections: ["descend", "ascend"],
    width: "200px",
    align: "center",
  },
  {
    title: "Disponibilité",
    dataIndex: "stock",
    key: "stock",
    scopedSlots: { customRender: "stock" },
    sorter: (a, b) => a.stock.localeCompare(b.stock),
    sortDirections: ["descend", "ascend"],
    width: "75px",
    align: "center",
  },
  {
    title: "Prix",
    key: "price",
    dataIndex: "price",
    scopedSlots: { customRender: "price" },
    sorter: (a, b) => (a.pu !== b.pu ? (a.pu < b.pu ? -1 : 1) : 0),
    sortDirections: ["descend", "ascend"],
    width: "75px",
    align: "center",
  },
  {
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "50px",
    align: "center",
  },
];
export default {
  data() {
    return {
      addCartLoading: false,
      columns: null,
      checkedList: null,
      comArticle: "",
      indeterminate: true,
      checkAll: false,
      articles: [],
      gammes: [],
      defaultListArticles: [],
      searchInputValue: "",
      nbFavorites: 0,
      favoriteIsSelected: false,
      searchResults: null,
      commentResult: " résultats trouvés",
      starStyle: {
        color: "#aaa",
        fontSize: "24px",
        textAlign: "center",
        margin: "auto",
      },
      visibleSpin: true,
      visibleModal: false,
      article: null,
      productQuantity: 1,
      totalProduct: null,
      totalEcoTaxe: null,
      options: null,
      gammeTdClass: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "50px",
        width: "125px",
        marginBottom: "unset",
        margin: "0 auto",
      },
      showPrice: true,
    };
  },
  // computed: {
  //   filteredColumns: function () {
  //     // return this.columns.filter(function (el) { return el.key !== 'price' || this.showPrice })
  //     return this.columns.filter(el => el.key !== 'price' || true) //this.showPrice)
  //   }
  // },
  methods: {
    async getCSVProductsList() {
      const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
      const client_code = JSON.parse(
        localStorage.getItem("userInfos")
      ).CODE_CLIENT;
      const params = {
        token: token,
        client_code: client_code,
      };
      await this.$store.dispatch("getCSVProductsList", params);
    },
    async addCart(article, quantity) {
      this.visibleModal = false;
      // this.visibleSpin = true;
      const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
      let params = { token: token, article: article, quantity: quantity };
      if (this.comArticle !== null || this.comArticle !== "") {
        params.article.com = this.comArticle;
      }
      this.productQuantity = 1;
      this.comArticle = "";
      await this.$store.dispatch("addLineCart", params);
      // setTimeout(() => {
      // this.visibleSpin = false;
      // }, 500);
    },
    showModal(article) {
      this.article = article;
      this.visibleModal = true;
      this.totalProduct = Number(article.puRemise);
      this.totalEcoTaxe = article.ecoTaxe;
    },
    closeModal() {
      this.visibleModal = false;
      this.productQuantity = 1;
      this.comArticle = "";
    },
    onChange() {
      this.onSearch();
    },
    InputQuantityChange(product) {
      this.totalProduct = this.productQuantity * product.puRemise;
      this.totalEcoTaxe = this.productQuantity * product.ecoTaxe;
    },
    showFavorite() {
      this.favoriteIsSelected = !this.favoriteIsSelected;
      this.favoriteIsSelected
        ? (this.starStyle.color = "#ebc334")
        : (this.starStyle.color = "#aaa");
      this.onSearch();
    },
    changePriceVisibility() {
      console.log('%cProducts.vue line:248 value', 'color: #007acc;', this.showPrice);
      this.showPrice = !this.showPrice
      localStorage.setItem('showPrice', JSON.stringify(this.showPrice))
      this.columns = columns.filter(el => (el.key !== 'price' || this.showPrice))
    },
    onSearch() {
      const searchValues = this.searchInputValue.trim().split(" ");
      if (searchValues.length > 0 && searchValues.length > 0) {
        this.articles = this.defaultListArticles.filter((article) => {
          let result = true;
          if (this.checkedList !== null && this.checkedList.length > 0) {
            if (!this.checkedList.includes(article.gamme.lib)) return false;
          }
          if (this.favoriteIsSelected) {
            if (article.NB_CDE < 1) {
              return false;
            }
          }
          searchValues.forEach((value) => {
            if (
              article.lib.toUpperCase().includes(value.toUpperCase()) ||
              article.id.toUpperCase().includes(value.toUpperCase())
            ) {
              return;
            } else {
              result = false;
              return;
            }
          });
          return result;
        });
        if (this.searchInputValue.length === 0) {
          this.searchResults = null;
        } else {
          this.searchResults = this.articles.length;
        }
        if (this.articles.length === 0) {
          this.commentResult = "Aucun résultat";
        } else if (this.articles.length === 1) {
          this.commentResult = this.articles.length + " résutat trouvé";
        } else {
          this.commentResult = this.articles.length + " résultats trouvés";
        }
      } else {
        this.articles = this.defaultListArticles;
        this.searchResults = null;
        this.commentResult = "";
      }
    },
    setCountGamme(gamme) {
      return this.articles.filter((article) => article.gamme.lib === gamme)
        .length;
    },
    setLibGamme(id) {
      let res;
      this.gammes.forEach((el) => {
        if (id == el.id) {
          res = {
            lib: el.lib,
            color: el.color,
          };
          return;
        }
      });
      return res;
    },
    setColorGamme(id) {
      let res;
      this.gammes.forEach((el) => {
        if (id == el.id) {
          res = el.color;
          return;
        }
      });
      return res;
    },
    setStock(data) {
      return data
        ? '<span style="width:14px; height:14px; background:#22844e">&nbsp;&nbsp;&nbsp;&nbsp;</span><span>&nbsp;&nbsp;En stock</span>'
        : '<span style="width:14px; height:14px; background:#a8a8a8">&nbsp;&nbsp;&nbsp;&nbsp;</span><span>&nbsp;&nbsp;À fabriquer</span>';
    },
  },
  async mounted() {
    this.columns = columns.filter(el => el.key != "price")
    const tmpshowPrice = JSON.parse(localStorage.getItem("showPrice"));
    if (tmpshowPrice == null) {
      localStorage.setItem('showPrice', JSON.stringify(true))
      this.showPrice = true
      this.columns = columns
    } else {
      this.showPrice = tmpshowPrice
      this.columns = columns.filter(el => (el.key !== 'price' || this.showPrice))
    }
    let titleHeader = {
      title: "Liste des produits",
      subTitle: "Vous avez accès à " + "..." + " articles",
      icon: '<i aria-label="icon: unordered-list" class="anticon anticon-unordered-list"><svg viewBox="64 64 896 896" data-icon="unordered-list" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"></path></svg></i>',
    };
    await this.$store.dispatch("setHeaderTitle", titleHeader);

    const tokenInfos = JSON.parse(sessionStorage.getItem("tokenInfos"));
    if (tokenInfos) {
      const token = tokenInfos.TOKEN;
      const result = await this.$store.dispatch("getProducts", token);
      this.gammes = result.gammes.map((data) => {
        return {
          id: data.ID,
          lib: data.LIB,
          color: data.COULEUR,
        };
      });
      this.nbFavorites = 0;
      this.articles = result.articles.map((data) => {
        // let remise = "" + data.REMISE.RH;
        // if (remise > 0) {
        //   price = (price + Math.floor(price * (remise * 1)) / 100).toFixed(2);
        // } else if (remise < 0) {
        //   price = (price - Math.ceil(price * (remise * -1)) / 100).toFixed(2);
        // }
        const pubrut = data.PU;
        let remise = data.REMISE.RH;
        let price = Math.round(100 * pubrut * (1 + remise / 100)) / 100;
        if (data.REMISE.INVISIBLEH) {
          remise = " €";
        } else {
          if (data.REMISE.RH !== 0) {
            remise =
              ' €<br /><span style="font-size: 14px;">(' +
              data.PU.toFixed(2) +
              "€ " +
              data.REMISE.RH +
              "% de remise)</span>";
          } else {
            remise = " €";
          }
        }
        let lib = "";
        if (
          data.REF_CLIENT.CODE_ARTICLE !== "" &&
          data.REF_CLIENT.LIB01 !== ""
        ) {
          lib =
            '<span style="font-weight: bolder;">' +
            data.REF_CLIENT.CODE_ARTICLE +
            "</span> <br> <span> " +
            data.REF_CLIENT.LIB01 +
            " " +
            data.REF_CLIENT.LIB02 +
            "</span>";
        } else {
          lib =
            '<span style="font-weight: bolder;">' +
            data.LIB01 +
            "</span> <br> <span> " +
            data.LIB02 +
            " " +
            data.LIB03 +
            "</span>";
        }
        if (data.NB_CDE > 0) {
          this.nbFavorites++;
          lib = '<span v-if="!favoriteIsSelected"> ⭐ </span>' + lib;
        }
        let res = {
          id: data.CODE_ARTICLE,
          gamme: this.setLibGamme(data.IDGAMME),
          color: this.setColorGamme(data.IDGAMME),
          stock: this.setStock(data.EN_STOCK),
          NB_CDE: data.NB_CDE,
          lib: lib,
          pu: data.PU,
          ecoTaxe: data.ECOTAXE.PRIX,
          puRemise: price,
          price:
            price +
            remise +
            '<br /><span style="color: #22844e; font-size: 14px;">écotaxe: ' +
            data.ECOTAXE.PRIX +
            " €</span>",
          remise: data.REMISE,
          refClient: data.REF_CLIENT,
        };
        return res;
      });

      titleHeader = {
        title: "Liste des produits",
        subTitle: "Vous avez accès à " + this.articles.length + " articles",
        icon: '<i aria-label="icon: unordered-list" class="anticon anticon-unordered-list"><svg viewBox="64 64 896 896" data-icon="unordered-list" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"></path></svg></i>',
      };
      await this.$store.dispatch("setHeaderTitle", titleHeader);

      this.defaultListArticles = this.articles;
      this.options = result.gammes.map((data) => {
        return {
          label: data.LIB + " (" + this.setCountGamme(data.LIB) + ")",
          value: data.LIB,
        };
      });
    }
    this.visibleSpin = false;
  },
};
</script>

<style lang="scss" scoped>
.ant-spin-container {
  margin-bottom: 128px !important;
}

.spin-class {
  display: flex;
  justify-content: center;
  // height: 100%;
  // margin-bottom: 64px;
}

.rubrique-filtre {
  margin: auto 16px;
  padding: 8px;
}

.products {
  background: white;
  padding: 2% 4%;

  &-datatable {
    margin-top: 4px;
    margin-bottom: 64px;
    width: 95%;
    margin-left: 2.5%;

    &-price,
    &-total {
      font-size: 16px;
    }

    //white-space: pre-line;
    &-stock {
      width: 10%;
      // max-width: 350px;
      // min-width: 150px;
    }
  }

  &-filters {
    padding: 1%;
    border-radius: 8px;
    // border: 1px solid #aca6a6;
    border: 1px solid #aaa;

    &-all {
      margin-bottom: 15px;
    }

    &-list {
      width: 200px;
      display: flex;
      flex-direction: column;
    }
  }

  &-header {
    //display: flex;
    //position: fixed;
    top: 75px;
    left: 215px;
    right: 15px;
    z-index: 2;
    justify-content: space-between;
    padding-top: 50px;
    padding: 16px;
    background: white;
  }

  &-modal {
    &-comm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      textarea,
      button {
        margin-top: 15px;
      }

      button {
        width: 50%;
      }
    }

    &-gamme,
    &-lib {
      text-align: center;
      white-space: pre-line;
      //padding: 0 20px;
    }

    &-gamme {
      padding-bottom: 10px;
    }

    &-lib {
      padding-bottom: 25px;
    }

    &-price {
      height: 150px;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
    }

    &-sell {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-total {
      margin-top: 5px;
      font-weight: bold;
    }

    &-taxe {
      margin-bottom: 5px;
      color: #22844e;
      font-size: 14px;
    }

    &-tranche {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
      margin-top: 20px;
    }

    &-alt-mode {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;

      justify-content: center;
      // padding-left: 20px;
      // margin-top: 20px;
    }
  }

  &-search {
    width: 360px;
    max-width: 95%;
    margin: auto;
    margin-top: 16px;
    display: flex;

    &-result {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 40px;
    }
  }

  &-display-price {
    margin-top: 200px;
  }
}

.downloadLink {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 256px;
  max-width: 100%;
  min-height: 40px;
  margin: auto;
  margin-top: 16px;
}
</style>