<template>
  <div>
    <h1 v-if="this.linesCartInfos.length === 0">Pas de panier en cours</h1>
    <a-form :form="form" class="delivery" @submit="handleSubmit">
      <a-row :gutter="[16, 16]">
        <a-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
          <div class="delivery-address" v-if="userInfos.LIVRAISON !== undefined">
            <h1 style="text-align: center">ADRESSE DE LIVRAISON</h1>
            <!-- <h3 v-if="userInfos.SECTEUR == 'DIST'" class="warning-delivering">
              Attention : Pour toute livraison aux particuliers, merci de nous
              consulter au préalable.
            </h3> -->
            <div v-if="addresses">
              <div v-if="addresses.length > 0">
                <!-- {{ filteredAddresses }} 
                @search="filterAddresses"-->
                <a-select placeholder=" - CP Ville Nom - " show-search option-filter-prop="children"
                  :filter-option="filterOption" @change="onChangeAddress" :allowClear="false">
                  <a-select-option v-for="address in filteredAddresses" :label="address.LIBELLE" :value="address.ID"
                    :key="address.ID">
                    {{ address.CP }} {{ address.VILLE }} {{ address.NOM }}
                  </a-select-option>
                </a-select>
                <hr />
              </div>
            </div>
            <a-form-item class="delivery-address-item" label="Nom livraison">
              <a-input v-decorator="[
                'name',
                {
                  initialValue: userInfos.LIVRAISON.NOM,
                  rules: [
                    {
                      max: 40,
                      required: true,
                      message:
                        'Merci de renseigner un nom valide! (40 caractères max)',
                    },
                  ],
                },
              ]" />
            </a-form-item>
            <a-form-item class="delivery-address-item" label="Rue 1">
              <a-input v-decorator="[
                'way1',
                {
                  initialValue: userInfos.LIVRAISON.RUE1,
                  rules: [
                    {
                      max: 40,
                      required: true,
                      message:
                        'Merci de renseigner une rue de livraison valide! (40 caractères max)',
                    },
                  ],
                },
              ]" />
            </a-form-item>
            <a-form-item class="delivery-address-item" label="Rue 2">
              <a-input v-decorator="[
                'way2',
                {
                  initialValue: userInfos.LIVRAISON.RUE2,
                  rules: [
                    {
                      max: 40,
                      required: false,
                      message: 'Complément de rue (40 caractères max)',
                    },
                  ],
                },
              ]" />
            </a-form-item>
            <a-form-item class="delivery-address-code" label="Code postal">
              <a-input @blur="getExtracost(true)" v-decorator="[
                'postalCode',
                {
                  initialValue: userInfos.LIVRAISON.CODEPOSTAL,
                  rules: [
                    {
                      trigger: 'blur',
                      maxLength: 16,
                      required: true,
                      message:
                        'Merci de renseigner un code postal valide! (16 caractères max)',
                    },
                  ],
                },
              ]" />
            </a-form-item>
            <a-form-item class="delivery-address-town" label="Ville">
              <a-input v-decorator="[
                'city',
                {
                  initialValue: userInfos.LIVRAISON.VILLE,
                  rules: [
                    {
                      max: 40,
                      required: true,
                      message:
                        'Merci de renseigner une ville valide! (40 caractères max)',
                    },
                  ],
                },
              ]" />
            </a-form-item>
            <a-form-item class="delivery-address-item" label="Pays">
              <a-select class="form-input-select" @change="handleDeliverySelectChange" placeholder="Sélectionnez un pays"
                v-decorator="[
                  'country',
                  {
                    initialValue: selectedDeliveryCountry,
                    rules: [
                      { required: true, message: 'Veuillez choisir un pays' },
                    ],
                  },
                ]">
                <a-select-option v-for="(dcountry, index) in countries" :key="index" :value="dcountry.CHAINES[2]">
                  <span v-if="dcountry.ACTIF">{{ dcountry.CHAINES[0] }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item class="delivery-address-item" label="Instructions livraison">
              <a-input v-decorator="[
                'instructions',
                {
                  initialValue: userInfos.LIVRAISON.INSTRUCTION,
                  rules: [
                    {
                      required: false,
                      max: 40,
                      message: 'Instructions (40 caractères max)',
                    },
                  ],
                },
              ]" />
            </a-form-item>
          </div>
        </a-col>
        <a-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
          <div class="delivery-infoContact">
            <h1 style="text-align: center">CONTACT DE LIVRAISON</h1>
            <a-form-item class="delivery-address-item" label="Nom du contact de livraison">
              <a-input v-decorator="[
                'nameContact',
                {
                  initialValue: '',
                  rules: [
                    {
                      max: 40,
                      required: true,
                      message:
                        'Merci de renseigner un nom valide! (40 caractères max)',
                    },
                  ],
                },
              ]" />
            </a-form-item>
            <a-form-item class="delivery-address-item" label="Mail du contact de livraison">
              <a-input v-decorator="[
                'mailContact',
                {
                  initialValue: '',
                  rules: [
                    {
                      trigger: 'blur',
                      type: 'email',
                      required: false,
                      message: 'Merci de renseigner un email valide!',
                    },
                    {
                      trigger: 'blur',
                      max: 40,
                      required: false,
                      message:
                        'Veuillez n\'entrer que 40 caractères maximum.',
                    },
                  ],
                },
              ]" />
            </a-form-item>
            <a-form-item class="delivery-address-item" label="Tél du contact de livraison">
              <a-input placeholder="0612345678" type="phone" v-decorator="[
                'telContact',
                {
                  initialValue: '',
                  rules: [
                    {
                      trigger: 'blur',
                      pattern: /^((\+33\s?.?|0)(\d)(\s?.?\d\d){4})$/,
                      min: 10,
                      max: 12,
                      required: true,
                      message:
                        'Merci de renseigner un numéro de téléphone valide!',
                    },
                  ],
                },
              ]" />
            </a-form-item>
          </div>
        </a-col>
        <a-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
          <a-spin tip="Chargement..." size="large" :spinning="visibleSpin">
            <div class="delivery-date">
              <h1 style="text-align: center;">DATE DE DÉPART ESTIMÉE</h1>
              <p style="text-align: justify">
                Le départ se fera au plus tôt le
                {{ dateLocale }}
              </p>
              <a-form-item>
                <a-week-picker v-if="accountType == 'DIST'" class="delivery-date-input" placeholder="Semaine de livraison"
                  @change="onChangeDate" :locale="locale" :disabled-date="disabledDate" :allowClear="false"
                  format="[Semaine] ww - YYYY" v-decorator="[
                    'deliveryDate',
                    {
                      rules: [
                        {
                          initialValue: moment(new Date(furtherDate)),
                          type: 'date',
                          trigger: 'blur',
                          required: true,
                          message: 'Merci de renseigner une semaine de livraison!',
                        },
                      ],
                    },
                  ]" />
                <a-date-picker v-else class="delivery-date-input" :locale="locale" :disabled-date="disabledDate"
                  :defaultPickerValue="moment(new Date(furtherDate))" :allowClear="false" format="DD MMMM YYYY"
                  :showToday="false" :changeMonth="true" :changeYear="false" @change="onChangeDate" v-decorator="[
                    'deliveryDate',
                    {
                      rules: [
                        {
                          initialValue: moment(new Date(furtherDate)),
                          type: 'date',
                          trigger: 'blur',
                          required: true,
                          message:
                            'Merci de renseigner une date de livraison!',
                        },
                      ],
                    },
                  ]" />
              </a-form-item>

              <h2>Frais de port:</h2>
              <div class="div-fdp">
                <div v-if="!loadingExtraCost" style="min-height: 64px;">
                  <p>
                    <b v-if="surcout >= 0">{{ surcout }} €</b>
                  </p>
                  <p style="text-align: justify">{{ surcoutDescript }}</p>
                </div>
                <div v-else style="min-height: 64px;">
                  <a-icon slot="indicator" type="loading" style="font-size: 24px" />
                </div>
                <a-button type="primary" icon="calculator" :loading="loadingExtraCost" @click="getExtracost(true)">
                  Recalculer
                </a-button>
              </div>
              <h1>Référence commande</h1>
              <a-form-item>
                <a-input :maxLength="20" class="delivery-ref-input" placeholder="Saisissez si besoin votre ref"
                  v-decorator="[
                    'refCde',
                    {
                      initialValue: cart.REFCDE,
                      rules: [
                        {
                          required: false,
                          max: 20,
                          message: 'Ref Commande (20 caractères max)',
                        },
                      ],
                    },
                  ]" />
              </a-form-item>
              <a-checkbox :checked="isCheckedCGV" @change="onChangeCGV" class="CGV-checkbox" valuePropName="isCheckedCGV"
                v-decorator="[
                  'isCheckedCGV',
                  {
                    initialValue: false,
                    rules: [
                      {
                        required: true,
                        transform: (value) => value || undefined,
                        trigger: 'change',
                        type: 'boolean',
                        message: 'Veuillez accepter nos CGV.',
                      },
                    ],
                  },
                ]">
                Accepter les
                <em @click.prevent="visibleCGVModal = true" class="CGV-link">Conditions Générales de Vente</em>
              </a-checkbox>
              <br />
              <a-button :disabled="blockValidation || !isCheckedCGV" class="delivery-date-button" type="primary"
                html-type="submit">
                VALIDER LE PANIER
              </a-button>
            </div>
          </a-spin>
        </a-col>
      </a-row>
      <a-modal class="modal-CGV" centered v-if="visibleCGVModal" v-model="visibleCGVModal"
        title="Conditions Générales de Vente" @cancel="visibleCGVModal = false">
        <componentCGVHott v-if="accountType == 'COLL'" />
        <componentCGVDistri v-else />
        <template slot="footer">
          <div class="delivery-modal-button">
            <a-button type="primary" @click="
              () => {
                isCheckedCGV = true;
                visibleCGVModal = false;
              }
            ">J'ai compris</a-button>
          </div>
        </template>
      </a-modal>
      <a-modal class="delivery-modal" centered v-if="visibleModal" v-model="visibleModal" :footer="null"
        @cancel="closeModal">
        <h2 class="delivery-modal-title">Panier validé</h2>
        <p>Numéro de panier: {{ modalContent.PANIER_ID }}</p>
        <p>Un mail de validation vous a été envoyé.</p>
        <div class="delivery-modal-button">
          <a-button type="primary" @click="closeModal">OK</a-button>
        </div>
      </a-modal>
    </a-form>
  </div>
</template>

<script>
import moment from "moment";
import locale from "ant-design-vue/es/date-picker/locale/fr_FR";
import notificationManager from "../helpers/notificationManager";
import componentCGVDistri from "@/components/componentCGVDistri.vue";
import componentCGVHott from "@/components/componentCGVHott.vue";
export default {
  components: {
    componentCGVDistri,
    componentCGVHott,
  },
  data() {
    return {
      accountType: "",
      visibleModal: false,
      visibleSpin: true,
      loadingExtraCost: false,
      blockValidation: false,

      testValue: [],
      addresses: [],
      filteredAddresses: [],
      countries: [],
      cart: {},
      form: this.$form.createForm(this, { name: "cart_validation" }),
      isCheckedCGV: false,
      linesCartInfos: {},
      locale,
      selectedAddress: {},
      selectedDeliveryCountry: null,
      userInfos: {},
      visibleCGVModal: false,

      furtherDate: null,
      dateFormatted: null,
      dateLocale: null,
      dateMoment: null,
      deliveryDate: null,
      deliveryDateFilled: false,
      joursRestants: null,
      listeDatesLivraison: null,
      modalContent: false,

      datesFermees: [],
      surcout: 0,
      surcoutDescript: "",
    };
  },
  methods: {
    moment,
    filterOption(input, option) {
      var tabInputs = input.trim().toLowerCase().split(' ')
      var theText =
        option.componentOptions.children[0].text
          .trim()
          .toLowerCase()

      var occ = 0
      tabInputs.forEach(it => {
        if (theText.indexOf(it) >= 0) {
          occ++
        }
      })
      return occ == tabInputs.length
    },
    onChangeAddress(monAdresse) {
      this.selectedAddress = {};
      if (monAdresse !== undefined) {
        this.addresses.forEach((address) => {
          if (monAdresse == address.ID) {
            this.selectedAddress = address;
          }
        });

        this.form.setFieldsValue({ ["name"]: this.selectedAddress.NOM });
        this.form.setFieldsValue({ ["way1"]: this.selectedAddress.RUE1 });
        this.form.setFieldsValue({ ["way2"]: this.selectedAddress.RUE2 });
        this.form.setFieldsValue({ ["postalCode"]: this.selectedAddress.CP });
        this.form.setFieldsValue({ ["city"]: this.selectedAddress.VILLE });
        this.form.setFieldsValue({ ["country"]: this.selectedAddress.PAYS });
      } else {
        this.form.setFieldsValue({ ["name"]: this.userInfos.LIVRAISON.NOM });
        this.form.setFieldsValue({ ["way1"]: this.userInfos.LIVRAISON.RUE1 });
        this.form.setFieldsValue({ ["way2"]: this.userInfos.LIVRAISON.RUE2 });
        this.form.setFieldsValue({
          ["postalCode"]: this.userInfos.LIVRAISON.CODEPOSTAL,
        });
        this.form.setFieldsValue({ ["city"]: this.userInfos.LIVRAISON.VILLE });
        this.form.setFieldsValue({
          ["country"]: this.userInfos.LIVRAISON.CODEPAYS,
        });
      }
      this.getExtracost(true);
    },
    onChangeCGV(event) {
      this.isCheckedCGV = event.target.checked;
    },
    closeModal() {
      this.$router.push("/paniers-saisis");
    },
    // étape 1 : appel API à ESTIMERDELAI
    async estimateDelay(token) {
      var ARTICLES = [];
      let index = 0;
      this.linesCartInfos.forEach(
        (article) =>
        (ARTICLES[index++] = {
          CODE_ARTICLE: article.CODE_ARTICLE,
          QTE: article.QTE,
        })
      );
      const params = { token: token, body: ARTICLES };
      const delay = await this.$store.dispatch("getEstimerDelai", params);
      if (delay.length > 0) {
        this.listeDatesLivraison = delay;
        this.selectFurthestDate();
      } else {
        const millis = new Date().getTime();
        let twoWeeksLater = new Date(new Date(millis + 3600000 * 24 * 14));
        let dateMoment = moment(twoWeeksLater).format("YYYY-MM-DD");
        this.listeDatesLivraison = [dateMoment];
        this.selectFurthestDate();
        return;
      }
    },
    // étape 2 : sélection date la plus lointaine du panier
    selectFurthestDate() {
      let furtherDate = this.listeDatesLivraison[0];
      this.listeDatesLivraison.forEach(function (date) {
        if (date > furtherDate) {
          furtherDate = date;
        }
      });
      this.furtherDate = furtherDate;
      this.formateDateExpedition();
    },
    // étape 3 : formattage pour affichage et calendrier
    formateDateExpedition() {
      let furtherDate = new Date(moment(this.furtherDate));
      let datePlusThree = new Date(new Date().getTime() + 3600000 * 24 * 3);
      let comparedDate = new Date();
      if (datePlusThree < furtherDate) {
        comparedDate = furtherDate
      } else {
        comparedDate = datePlusThree
      }
      this.dateFormatted = comparedDate;

      const now = new Date(Date.now());
      let joursRestants = parseInt(
        Math.floor((this.dateFormatted - now) / (3600000 * 24))
      );
      let dateMoment = moment(this.dateFormatted);
      this.dateMoment = dateMoment;
      this.dateLocale = new Date(dateMoment).toLocaleDateString("fr-FR", {
        /*weekday:'long',*/ year: "numeric",
        month: "long",
        day: "numeric",
      });
      this.joursRestants = joursRestants;
    },
    async onChangeDate(date) {
      this.deliveryDate = date.format("YYYY-MM-DD");
      if (this.deliveryDate !== null) {
        this.deliveryDateFilled = true;
        this.dateFormatted = new Date(this.deliveryDate);
        let dateMoment = moment(new Date());
        this.dateMoment = dateMoment.format("DD MMMM YYYY");
        await this.getExtracost(true);
      } else {
        this.deliveryDateFilled = false;
      }
    },
    disabledDate(current) {
      let disable = false;
      if (current && current.valueOf() <= Date.now()) {
        disable = true;
      } else if (
        current &&
        current.valueOf() <= new Date(new Date().getTime() + 3600000 * 24 * 3)
      ) {
        disable = true;
      } else if (
        current &&
        current.valueOf() >= new Date(new Date().getTime() + 3600000 * 24 * 364)
      ) {
        disable = true;
      } else {
        // disable = false;
        if (this.datesFermees.includes(current.format("YYYY-MM-DD"))) {
          disable = true;
        } else {
          disable = false;
          if (current < moment().endOf("day").add(this.joursRestants, "days")) {
            disable =
              current &&
              current < moment().endOf("day").add(this.joursRestants, "days");
          } else {
            if (current.day() === 0) {
              disable = false;
            } else {
              disable = false;
            }
          }
        }
      }
      return disable;
    },
    handleDeliverySelectChange(value) {
      this.selectedDeliveryCountry = value;
    },
    async checkCloture() {
      const tokenInfos = JSON.parse(sessionStorage.getItem("tokenInfos"));
      if (tokenInfos) {
        const token = tokenInfos.TOKEN;
        const cart = localStorage.getItem("cartInfos");
        if (cart) {
          let cartId = JSON.parse(localStorage.getItem("cartInfos")).PANIER_ID;
          const params = {
            token: token,
            id: cartId,
          };
          const entete = await this.$store.dispatch("getEnteteCart", params);
          if (entete.code === 0) {
            if (!entete.data.CLOTURE) {
              return true;
            }
          }
        }
      }
      return false;
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.blockValidation = true;
      this.visibleSpin = true;
      const checkCartStatus = await this.checkCloture();
      if (checkCartStatus) {
        this.form.validateFields(async (err, values) => {
          if (!err) {
            if (values.mailContact.length < 40) {
              let params = {
                token: JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN,
                id: JSON.parse(localStorage.getItem("cartInfos")).PANIER_ID,
                deliveryDate: this.deliveryDate,
                refCde: values.refCde.trim() !== "" ? values.refCde.trim() : "",
                livraison: {
                  CONTACT: {
                    NOM:
                      values.nameContact !==
                        this.userInfos.LIVRAISON.CONTACT.NOM
                        ? values.nameContact.trim()
                        : this.userInfos.LIVRAISON.CONTACT.NOM,
                    MAIL:
                      values.mailContact !==
                        this.userInfos.LIVRAISON.CONTACT.MAIL
                        ? values.mailContact.trim()
                        : this.userInfos.LIVRAISON.CONTACT.MAIL,
                    TEL:
                      values.telContact !== this.userInfos.LIVRAISON.CONTACT.TEL
                        ? values.telContact.trim()
                        : this.userInfos.LIVRAISON.CONTACT.TEL,
                  },
                  NOM:
                    values.name !== this.userInfos.LIVRAISON.NOM
                      ? values.name.trim()
                      : this.userInfos.LIVRAISON.NOM,
                  RUE1:
                    values.way1 !== this.userInfos.LIVRAISON.RUE1
                      ? values.way1.trim()
                      : this.userInfos.LIVRAISON.RUE1,
                  RUE2:
                    values.way2 !== this.userInfos.LIVRAISON.RUE2
                      ? values.way2.trim()
                      : this.userInfos.LIVRAISON.RUE2,
                  CODEPOSTAL:
                    values.postalCode !== this.userInfos.LIVRAISON.CODEPOSTAL
                      ? values.postalCode //.toString()
                      : this.userInfos.LIVRAISON.CODEPOSTAL,
                  VILLE:
                    values.city !== this.userInfos.LIVRAISON.VILLE
                      ? values.city.trim()
                      : this.userInfos.LIVRAISON.VILLE,
                  CODEPAYS:
                    values.country !== this.userInfos.LIVRAISON.CODEPAYS
                      ? values.country
                      : this.userInfos.LIVRAISON.CODEPAYS,
                  INSTRUCTIONS:
                    values.instructions.trim() !==
                      this.userInfos.LIVRAISON.INSTRUCTIONS
                      ? values.instructions.trim()
                      : this.userInfos.LIVRAISON.INSTRUCTIONS,
                },
              };
              this.modalContent = await this.$store.dispatch(
                "validateCart",
                params
              );
              if (this.modalContent) {
                this.visibleModal = true;
                this.visibleSpin = false;
              }
            } else {
              notificationManager.openNotificationWithIcon(
                "error",
                "Adresse email incorrect",
                {
                  description:
                    "Adresse email du contact de livraison : 40 caractères maximum",
                }
              );
              this.visibleSpin = false;
            }
          } else {
            this.visibleSpin = false;
          }
        });
      }
      this.blockValidation = false;
    },
    async getDatesFermetures(token) {
      await this.$store.dispatch("getDatesFermees", token);
      this.datesFermees = localStorage.getItem("datesFermees");
    },
    async getExtracost(reload) {
      const checkCartStatus = await this.checkCloture();
      if (checkCartStatus) {
        this.loadingExtraCost = true;
        this.surcout = -1;
        this.surcoutDescript = "Veuillez remplir les champs obligatoires"
        const tokenInfos = JSON.parse(sessionStorage.getItem("tokenInfos"));
        if (tokenInfos) {
          const token = tokenInfos.TOKEN;
          if (reload) {
            this.form.validateFields(async (err, values) => {
              if (!err) {
                var params = {
                  token: token,
                  PANIER_ID: this.cart.PANIER_ID,
                  CLIENT_CODE: this.userInfos.CODE_CLIENT,
                  DATE_LIVRAISON: this.deliveryDate,
                  MAILS: [this.userInfos.LOGIN],
                  livraison: {
                    CONTACT: {
                      NOM:
                        values.nameContact !==
                          this.userInfos.LIVRAISON.CONTACT.NOM
                          ? values.nameContact.trim()
                          : this.userInfos.LIVRAISON.CONTACT.NOM,
                      MAIL:
                        values.mailContact !==
                          this.userInfos.LIVRAISON.CONTACT.MAIL
                          ? values.mailContact.trim()
                          : this.userInfos.LIVRAISON.CONTACT.MAIL,
                      TEL:
                        values.telContact !==
                          this.userInfos.LIVRAISON.CONTACT.TEL
                          ? values.telContact.trim()
                          : this.userInfos.LIVRAISON.CONTACT.TEL,
                    },
                    NOM:
                      values.name !== this.userInfos.LIVRAISON.NOM
                        ? values.name.trim()
                        : this.userInfos.LIVRAISON.NOM,
                    RUE1:
                      values.way1 !== this.userInfos.LIVRAISON.RUE1
                        ? values.way1.trim()
                        : this.userInfos.LIVRAISON.RUE1,
                    RUE2:
                      values.way2 !== this.userInfos.LIVRAISON.RUE2
                        ? values.way2.trim()
                        : this.userInfos.LIVRAISON.RUE2,
                    CODEPOSTAL:
                      values.postalCode !== this.userInfos.LIVRAISON.CODEPOSTAL
                        ? values.postalCode //.toString()
                        : this.userInfos.LIVRAISON.CODEPOSTAL,
                    VILLE:
                      values.city !== this.userInfos.LIVRAISON.VILLE
                        ? values.city.trim()
                        : this.userInfos.LIVRAISON.VILLE,
                    CODEPAYS:
                      values.country !== this.userInfos.LIVRAISON.CODEPAYS
                        ? values.country
                        : this.userInfos.LIVRAISON.CODEPAYS,
                    INSTRUCTIONS:
                      values.instructions.trim() !==
                        this.userInfos.LIVRAISON.INSTRUCTIONS
                        ? values.instructions.trim()
                        : this.userInfos.LIVRAISON.INSTRUCTIONS,
                  },
                };

                await this.$store.dispatch(
                  "getExtracost",
                  params
                ).then(async (res) => {
                  if (res.code === 0) {
                    this.surcout = res.data.COUT;
                    this.surcoutDescript = "Le port de la commande a été déterminé.";
                  } else if (res.code == 15 || res.code == 16) {
                    if (res.code == 15) {
                      this.surcoutDescript =
                        "Le port n'a pas pu être déterminé. Il sera ajusté par l'équipe commerciale lors de la validation";
                    } else if (res.code == 16) {
                      this.surcoutDescript =
                        "Le port doit être déterminé par le service commercial.";
                    }
                    this.surcout = -1;
                  }
                })
                this.blockValidation = false;
                this.loadingExtraCost = false;
              } else {
                this.blockValidation = false;
                this.loadingExtraCost = false;
              }
            });
          } else {
            var params = {
              token: token,
              PANIER_ID: this.cart.PANIER_ID,
              CLIENT_CODE: this.userInfos.CODE_CLIENT,
              DATE_LIVRAISON: this.deliveryDate,
              MAILS: [this.userInfos.LOGIN],
              livraison: null,
            };
            await this.$store.dispatch(
              "getExtracost",
              params
            ).then(async (res) => {
              if (res.code === 0) {
                this.surcout = res.data.COUT;
                this.surcoutDescript =
                  "Le port de la commande a été déterminé.";
              } else if (res.code === 15 || res.code === 16) {
                if (res.code === 15) {
                  this.surcoutDescript =
                    "Le port n'a pas pu être déterminé. Il sera ajusté par l'équipe commerciale lors de la validation";
                } else if (res.code === 16) {
                  this.surcoutDescript =
                    "Le port doit être déterminé par le service commercial.";
                }
                this.surcout = -1;
              }
            })
            this.blockValidation = false;
            this.loadingExtraCost = false;
          }
        }
      }
    },
    async sendEmail(token) {
      const strUser = "Utilisateur: " + this.userInfos.USER_ID;
      const strCart = "Panier: " + this.cart.PANIER_ID;
      const base64Corps = btoa(`Le port n'a pas pu être déterminé. Il sera ajusté par l'équipe commerciale lors de la validation. ${strUser} ${strCart}`)
      const params = {
        token: token,
        body: {
          DESTINATAIRES: ["si@thiriez-literie.fr"],
          SUJET: `[${process.env.NODE_ENV}] ERREUR DANS LE CALCUL DE LA DATE DE LIVRAISON`,
          CORPS: base64Corps
        },
      };
      await this.$store.dispatch("sendEmail", params);
    },
  },
  created() {
    this.userInfos = JSON.parse(localStorage.getItem("userInfos"));
    this.countries = JSON.parse(localStorage.getItem("countriesInfos"));
    this.cart = JSON.parse(localStorage.getItem("cartInfos"));
    this.linesCartInfos = JSON.parse(localStorage.getItem("linesCartInfos"));
    this.selectedDeliveryCountry = this.userInfos.LIVRAISON.CODEPAYS;
    this.accountType = this.userInfos.SECTEUR;
  },
  async mounted() {
    this.visibleSpin = true;
    const tokenInfos = JSON.parse(sessionStorage.getItem("tokenInfos"));
    if (tokenInfos) {
      const token = tokenInfos.TOKEN;
      const cart = localStorage.getItem("cartInfos");
      if (cart) {
        const params = {
          token: token,
          id: JSON.parse(cart).PANIER_ID,
        };
        const entete = await this.$store.dispatch("getEnteteCart", params);
        if (entete.code === 0) {
          if (!entete.CLOTURE) {
            await this.$store.dispatch("getDeliveryAddress", token);
            this.addresses = JSON.parse(
              localStorage.getItem("deliveryAddress")
            );
            if (this.addresses) {
              this.addresses.sort(
                (a, b) => parseFloat(a.CP) - parseFloat(b.CP)
              );
              this.filteredAddresses = this.addresses;
            }

            await this.getDatesFermetures(token);
            await this.estimateDelay(token);
            let date = moment(new Date());
            this.deliveryDate = date.format("YYYY-MM-DD");
            if (this.deliveryDate) {
              await this.getExtracost(false);
            }
          }
        }
      } else {
        this.$store.state.cart = null;
        this.$store.state.cartStatus = null;
        this.$store.state.linesCart = [];
        this.$store.state.linesCartLength = 0;
        this.$router.push("/produits");
      }
      this.visibleSpin = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.warning-delivering {
  text-align: center;
  color: rgb(255, 0, 0);
  font-style: italic;
}

.delivery {
  width: 100%;
  height: 100%;

  &-address,
  &-date,
  &-infoContact {
    width: auto;
    margin: 5px auto;
    padding: 5px;
  }

  &-date,
  &-ref {
    &-input {
      width: 100%;
    }
  }

  &-date {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-button {
      margin: auto;
      padding: 10px;
      width: 100%;
      height: auto;
    }
  }

  &-address {
    &-city {
      display: flex;
      width: auto;
      margin: auto;
    }

    &-code {
      width: auto;
      margin: auto;
    }

    &-town {
      width: auto;
      margin: auto;
    }

    &-item {
      width: auto;
      margin: auto;
    }
  }

  &-input,
  &-button {
    width: 300px;
  }

  &-modal {
    &-button {
      text-align: center;
      width: 100%;
      margin-top: 10px;
    }

    &-title {
      text-align: center;
    }
  }
}

a-col {
  margin: 15px;
  padding: 15px;
}

.return {
  margin-bottom: 25px;
}

.div-fdp {
  text-align: center;
}

// .div-fdp * {
//   text-align: justify;
// }

.CGV-link {
  color: #000399;
  font-weight: bold;
  padding: 4px;
}

.CGV-link:hover {
  color: #0003d1;
  animation: all 2s;
}

.ant-modal-body {
  padding: 8px 0 8px 8px;
}
</style>