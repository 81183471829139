import axios from 'axios'
import { instance } from '../api.config'
import { ENDPOINTS, API } from "../constants.js"

const config = {
  auth: {
    username: API.clientId,
    password: API.clientSecret
  },
  headers: { "Access-Control-Allow-Origin": "*" }
}

var servUrl = "/gmao/login";
if (process.env.NODE_ENV === "development") {
  servUrl = "http://localhost:3000/gmao/login"
} else {
  servUrl = '/gmao/login'
}

export default {
  /**
   * 
   * @param {string} username 
   * @param {string} password 
   */
  async login(username, password) {
    let credentials = 'username=' + username + '&password=' + password
    try {
      const res = await instance.get(API.baseUrl + ENDPOINTS.login + credentials, config)
      return res;
    } catch (e) {
      return e;
    }
  },

  async callLogin(options) {
    try {
      options.config.headers = {
        ...options.config.headers
      };
      const response = await axios(options.config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return error.message;
    }
  },

  async loginOAuth(username, password) {
    const config = {
      url: servUrl,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: 'Basic ' + Buffer.from(username + ':' + password, 'utf-8').toString('base64')
      },
    };
    const res = await this.callLogin({ config });
    return res;
  },
}