var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.linesCartInfos.length === 0)?_c('h1',[_vm._v("Pas de panier en cours")]):_vm._e(),_c('a-form',{staticClass:"delivery",attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',{attrs:{"gutter":[16, 16]}},[_c('a-col',{attrs:{"xs":24,"sm":12,"md":8,"lg":8,"xl":8}},[(_vm.userInfos.LIVRAISON !== undefined)?_c('div',{staticClass:"delivery-address"},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("ADRESSE DE LIVRAISON")]),(_vm.addresses)?_c('div',[(_vm.addresses.length > 0)?_c('div',[_c('a-select',{attrs:{"placeholder":" - CP Ville Nom - ","show-search":"","option-filter-prop":"children","filter-option":_vm.filterOption,"allowClear":false},on:{"change":_vm.onChangeAddress}},_vm._l((_vm.filteredAddresses),function(address){return _c('a-select-option',{key:address.ID,attrs:{"label":address.LIBELLE,"value":address.ID}},[_vm._v(" "+_vm._s(address.CP)+" "+_vm._s(address.VILLE)+" "+_vm._s(address.NOM)+" ")])}),1),_c('hr')],1):_vm._e()]):_vm._e(),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Nom livraison"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'name',
              {
                initialValue: _vm.userInfos.LIVRAISON.NOM,
                rules: [
                  {
                    max: 40,
                    required: true,
                    message:
                      'Merci de renseigner un nom valide! (40 caractères max)',
                  },
                ],
              },
            ]),expression:"[\n              'name',\n              {\n                initialValue: userInfos.LIVRAISON.NOM,\n                rules: [\n                  {\n                    max: 40,\n                    required: true,\n                    message:\n                      'Merci de renseigner un nom valide! (40 caractères max)',\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Rue 1"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'way1',
              {
                initialValue: _vm.userInfos.LIVRAISON.RUE1,
                rules: [
                  {
                    max: 40,
                    required: true,
                    message:
                      'Merci de renseigner une rue de livraison valide! (40 caractères max)',
                  },
                ],
              },
            ]),expression:"[\n              'way1',\n              {\n                initialValue: userInfos.LIVRAISON.RUE1,\n                rules: [\n                  {\n                    max: 40,\n                    required: true,\n                    message:\n                      'Merci de renseigner une rue de livraison valide! (40 caractères max)',\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Rue 2"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'way2',
              {
                initialValue: _vm.userInfos.LIVRAISON.RUE2,
                rules: [
                  {
                    max: 40,
                    required: false,
                    message: 'Complément de rue (40 caractères max)',
                  },
                ],
              },
            ]),expression:"[\n              'way2',\n              {\n                initialValue: userInfos.LIVRAISON.RUE2,\n                rules: [\n                  {\n                    max: 40,\n                    required: false,\n                    message: 'Complément de rue (40 caractères max)',\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{staticClass:"delivery-address-code",attrs:{"label":"Code postal"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'postalCode',
              {
                initialValue: _vm.userInfos.LIVRAISON.CODEPOSTAL,
                rules: [
                  {
                    trigger: 'blur',
                    maxLength: 16,
                    required: true,
                    message:
                      'Merci de renseigner un code postal valide! (16 caractères max)',
                  },
                ],
              },
            ]),expression:"[\n              'postalCode',\n              {\n                initialValue: userInfos.LIVRAISON.CODEPOSTAL,\n                rules: [\n                  {\n                    trigger: 'blur',\n                    maxLength: 16,\n                    required: true,\n                    message:\n                      'Merci de renseigner un code postal valide! (16 caractères max)',\n                  },\n                ],\n              },\n            ]"}],on:{"blur":function($event){return _vm.getExtracost(true)}}})],1),_c('a-form-item',{staticClass:"delivery-address-town",attrs:{"label":"Ville"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'city',
              {
                initialValue: _vm.userInfos.LIVRAISON.VILLE,
                rules: [
                  {
                    max: 40,
                    required: true,
                    message:
                      'Merci de renseigner une ville valide! (40 caractères max)',
                  },
                ],
              },
            ]),expression:"[\n              'city',\n              {\n                initialValue: userInfos.LIVRAISON.VILLE,\n                rules: [\n                  {\n                    max: 40,\n                    required: true,\n                    message:\n                      'Merci de renseigner une ville valide! (40 caractères max)',\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Pays"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'country',
                {
                  initialValue: _vm.selectedDeliveryCountry,
                  rules: [
                    { required: true, message: 'Veuillez choisir un pays' },
                  ],
                },
              ]),expression:"[\n                'country',\n                {\n                  initialValue: selectedDeliveryCountry,\n                  rules: [\n                    { required: true, message: 'Veuillez choisir un pays' },\n                  ],\n                },\n              ]"}],staticClass:"form-input-select",attrs:{"placeholder":"Sélectionnez un pays"},on:{"change":_vm.handleDeliverySelectChange}},_vm._l((_vm.countries),function(dcountry,index){return _c('a-select-option',{key:index,attrs:{"value":dcountry.CHAINES[2]}},[(dcountry.ACTIF)?_c('span',[_vm._v(_vm._s(dcountry.CHAINES[0]))]):_vm._e()])}),1)],1),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Instructions livraison"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'instructions',
              {
                initialValue: _vm.userInfos.LIVRAISON.INSTRUCTION,
                rules: [
                  {
                    required: false,
                    max: 40,
                    message: 'Instructions (40 caractères max)',
                  },
                ],
              },
            ]),expression:"[\n              'instructions',\n              {\n                initialValue: userInfos.LIVRAISON.INSTRUCTION,\n                rules: [\n                  {\n                    required: false,\n                    max: 40,\n                    message: 'Instructions (40 caractères max)',\n                  },\n                ],\n              },\n            ]"}]})],1)],1):_vm._e()]),_c('a-col',{attrs:{"xs":24,"sm":12,"md":8,"lg":8,"xl":8}},[_c('div',{staticClass:"delivery-infoContact"},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("CONTACT DE LIVRAISON")]),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Nom du contact de livraison"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'nameContact',
              {
                initialValue: '',
                rules: [
                  {
                    max: 40,
                    required: true,
                    message:
                      'Merci de renseigner un nom valide! (40 caractères max)',
                  },
                ],
              },
            ]),expression:"[\n              'nameContact',\n              {\n                initialValue: '',\n                rules: [\n                  {\n                    max: 40,\n                    required: true,\n                    message:\n                      'Merci de renseigner un nom valide! (40 caractères max)',\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Mail du contact de livraison"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mailContact',
              {
                initialValue: '',
                rules: [
                  {
                    trigger: 'blur',
                    type: 'email',
                    required: false,
                    message: 'Merci de renseigner un email valide!',
                  },
                  {
                    trigger: 'blur',
                    max: 40,
                    required: false,
                    message:
                      'Veuillez n\'entrer que 40 caractères maximum.',
                  },
                ],
              },
            ]),expression:"[\n              'mailContact',\n              {\n                initialValue: '',\n                rules: [\n                  {\n                    trigger: 'blur',\n                    type: 'email',\n                    required: false,\n                    message: 'Merci de renseigner un email valide!',\n                  },\n                  {\n                    trigger: 'blur',\n                    max: 40,\n                    required: false,\n                    message:\n                      'Veuillez n\\'entrer que 40 caractères maximum.',\n                  },\n                ],\n              },\n            ]"}]})],1),_c('a-form-item',{staticClass:"delivery-address-item",attrs:{"label":"Tél du contact de livraison"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'telContact',
              {
                initialValue: '',
                rules: [
                  {
                    trigger: 'blur',
                    pattern: /^((\+33\s?.?|0)(\d)(\s?.?\d\d){4})$/,
                    min: 10,
                    max: 12,
                    required: true,
                    message:
                      'Merci de renseigner un numéro de téléphone valide!',
                  },
                ],
              },
            ]),expression:"[\n              'telContact',\n              {\n                initialValue: '',\n                rules: [\n                  {\n                    trigger: 'blur',\n                    pattern: /^((\\+33\\s?.?|0)(\\d)(\\s?.?\\d\\d){4})$/,\n                    min: 10,\n                    max: 12,\n                    required: true,\n                    message:\n                      'Merci de renseigner un numéro de téléphone valide!',\n                  },\n                ],\n              },\n            ]"}],attrs:{"placeholder":"0612345678","type":"phone"}})],1)],1)]),_c('a-col',{attrs:{"xs":24,"sm":12,"md":8,"lg":8,"xl":8}},[_c('a-spin',{attrs:{"tip":"Chargement...","size":"large","spinning":_vm.visibleSpin}},[_c('div',{staticClass:"delivery-date"},[_c('h1',{staticStyle:{"text-align":"center"}},[_vm._v("DATE DE DÉPART ESTIMÉE")]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(" Le départ se fera au plus tôt le "+_vm._s(_vm.dateLocale)+" ")]),_c('a-form-item',[(_vm.accountType == 'DIST')?_c('a-week-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'deliveryDate',
                  {
                    rules: [
                      {
                        initialValue: _vm.moment(new Date(_vm.furtherDate)),
                        type: 'date',
                        trigger: 'blur',
                        required: true,
                        message: 'Merci de renseigner une semaine de livraison!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'deliveryDate',\n                  {\n                    rules: [\n                      {\n                        initialValue: moment(new Date(furtherDate)),\n                        type: 'date',\n                        trigger: 'blur',\n                        required: true,\n                        message: 'Merci de renseigner une semaine de livraison!',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"delivery-date-input",attrs:{"placeholder":"Semaine de livraison","locale":_vm.locale,"disabled-date":_vm.disabledDate,"allowClear":false,"format":"[Semaine] ww - YYYY"},on:{"change":_vm.onChangeDate}}):_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'deliveryDate',
                  {
                    rules: [
                      {
                        initialValue: _vm.moment(new Date(_vm.furtherDate)),
                        type: 'date',
                        trigger: 'blur',
                        required: true,
                        message:
                          'Merci de renseigner une date de livraison!',
                      },
                    ],
                  },
                ]),expression:"[\n                  'deliveryDate',\n                  {\n                    rules: [\n                      {\n                        initialValue: moment(new Date(furtherDate)),\n                        type: 'date',\n                        trigger: 'blur',\n                        required: true,\n                        message:\n                          'Merci de renseigner une date de livraison!',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"delivery-date-input",attrs:{"locale":_vm.locale,"disabled-date":_vm.disabledDate,"defaultPickerValue":_vm.moment(new Date(_vm.furtherDate)),"allowClear":false,"format":"DD MMMM YYYY","showToday":false,"changeMonth":true,"changeYear":false},on:{"change":_vm.onChangeDate}})],1),_c('h2',[_vm._v("Frais de port:")]),_c('div',{staticClass:"div-fdp"},[(!_vm.loadingExtraCost)?_c('div',{staticStyle:{"min-height":"64px"}},[_c('p',[(_vm.surcout >= 0)?_c('b',[_vm._v(_vm._s(_vm.surcout)+" €")]):_vm._e()]),_c('p',{staticStyle:{"text-align":"justify"}},[_vm._v(_vm._s(_vm.surcoutDescript))])]):_c('div',{staticStyle:{"min-height":"64px"}},[_c('a-icon',{staticStyle:{"font-size":"24px"},attrs:{"slot":"indicator","type":"loading"},slot:"indicator"})],1),_c('a-button',{attrs:{"type":"primary","icon":"calculator","loading":_vm.loadingExtraCost},on:{"click":function($event){return _vm.getExtracost(true)}}},[_vm._v(" Recalculer ")])],1),_c('h1',[_vm._v("Référence commande")]),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                  'refCde',
                  {
                    initialValue: _vm.cart.REFCDE,
                    rules: [
                      {
                        required: false,
                        max: 20,
                        message: 'Ref Commande (20 caractères max)',
                      },
                    ],
                  },
                ]),expression:"[\n                  'refCde',\n                  {\n                    initialValue: cart.REFCDE,\n                    rules: [\n                      {\n                        required: false,\n                        max: 20,\n                        message: 'Ref Commande (20 caractères max)',\n                      },\n                    ],\n                  },\n                ]"}],staticClass:"delivery-ref-input",attrs:{"maxLength":20,"placeholder":"Saisissez si besoin votre ref"}})],1),_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'isCheckedCGV',
                {
                  initialValue: false,
                  rules: [
                    {
                      required: true,
                      transform: (value) => value || undefined,
                      trigger: 'change',
                      type: 'boolean',
                      message: 'Veuillez accepter nos CGV.',
                    },
                  ],
                },
              ]),expression:"[\n                'isCheckedCGV',\n                {\n                  initialValue: false,\n                  rules: [\n                    {\n                      required: true,\n                      transform: (value) => value || undefined,\n                      trigger: 'change',\n                      type: 'boolean',\n                      message: 'Veuillez accepter nos CGV.',\n                    },\n                  ],\n                },\n              ]"}],staticClass:"CGV-checkbox",attrs:{"checked":_vm.isCheckedCGV,"valuePropName":"isCheckedCGV"},on:{"change":_vm.onChangeCGV}},[_vm._v(" Accepter les "),_c('em',{staticClass:"CGV-link",on:{"click":function($event){$event.preventDefault();_vm.visibleCGVModal = true}}},[_vm._v("Conditions Générales de Vente")])]),_c('br'),_c('a-button',{staticClass:"delivery-date-button",attrs:{"disabled":_vm.blockValidation || !_vm.isCheckedCGV,"type":"primary","html-type":"submit"}},[_vm._v(" VALIDER LE PANIER ")])],1)])],1)],1),(_vm.visibleCGVModal)?_c('a-modal',{staticClass:"modal-CGV",attrs:{"centered":"","title":"Conditions Générales de Vente"},on:{"cancel":function($event){_vm.visibleCGVModal = false}},model:{value:(_vm.visibleCGVModal),callback:function ($$v) {_vm.visibleCGVModal=$$v},expression:"visibleCGVModal"}},[(_vm.accountType == 'COLL')?_c('componentCGVHott'):_c('componentCGVDistri'),_c('template',{slot:"footer"},[_c('div',{staticClass:"delivery-modal-button"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":() => {
              _vm.isCheckedCGV = true;
              _vm.visibleCGVModal = false;
            }}},[_vm._v("J'ai compris")])],1)])],2):_vm._e(),(_vm.visibleModal)?_c('a-modal',{staticClass:"delivery-modal",attrs:{"centered":"","footer":null},on:{"cancel":_vm.closeModal},model:{value:(_vm.visibleModal),callback:function ($$v) {_vm.visibleModal=$$v},expression:"visibleModal"}},[_c('h2',{staticClass:"delivery-modal-title"},[_vm._v("Panier validé")]),_c('p',[_vm._v("Numéro de panier: "+_vm._s(_vm.modalContent.PANIER_ID))]),_c('p',[_vm._v("Un mail de validation vous a été envoyé.")]),_c('div',{staticClass:"delivery-modal-button"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.closeModal}},[_vm._v("OK")])],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }