<template>
  <div class="orderPage">
    <div class="orderPage-header">
      <div class="orderPage-filters">
        <h3 class="card-title">Filtrer les documents</h3>
        <a-checkbox-group v-if="options" class="orderPage-filters-list" v-model="checkedList" :options="options" @change="onChange" />
      </div>
      <a-row>
        <a-col :xs="0" :sm="0" :md="8" :lg="8" :xl="8"></a-col>
        <a-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
          <a-input-search class="orderPage-search" placeholder="Saisissez votre recherche" @change="onSearch"
            v-model="searchInputValue" />
          <div v-if="searchResults !== null && searchResults <= 1" class="orderPage-search-result">
            <span>
              {{ searchResults }} résultat trouvé
            </span>
          </div>
          <div v-if="searchResults > 1" class="orderPage-search-result">
            <span>
              {{ searchResults }} résultats trouvés
            </span>
          </div>
          <div v-if="searchInputValue == ''" class="orderPage-search-result"></div>
        </a-col>
      </a-row>
    </div>
    <a-spin tip="Chargement..." size="large" :spinning="visibleSpin">
      <a-table class="orderPage-datatable" :scroll="{ y: 500 }" rowKey="DOC_ID" :columns="columns" :data-source="orders"
        bordered :locale="{ emptyText: 'Aucune commande disponible' }">
        <span slot="order" slot-scope="record" v-html="record"></span>
        <span class="orderPage-datatable-actions" slot="action" slot-scope="record">
          <a-button type="primary" @click="showModal(record, tempNumDoc)" :style="{ width: '125px' }">
            Consulter
          </a-button>
          <!-- 
          <a-button type="primary" @click="showSAV(record)" :style="{ width: '125px' }">
            SAV
          </a-button> 
          -->
        </span>
      </a-table>
    </a-spin>
    <a-modal class="orderPage-modal" centered v-if="visibleModal" v-model="visibleModal" :footer="null"
      @cancel="closeModal()">
      <h2 class="orderPage-modal-gamme" :style="[{ borderBottom: '5px solid #ccc', margin: '15px auto' }]">
        {{ orderTitle }}
      </h2>
      <!-- <a-table :pagination="false" :showHeader="false" rowKey="id" :columns="columns2" :data-source="docs" bordered
          :locale="{ emptyText: 'Aucune commande disponible' }">
          <span slot="action"> -->
      <div class="orderPage-modal-comm">
        <a-button type="primary" @click="downloadFileV2(docs)">
          Télécharger
        </a-button>
      </div>
      <!-- </span>
        </a-table> -->
    </a-modal>
  </div>
</template>

<script>
import moment from "moment";
const columns = [
  {
    title: "Document",
    dataIndex: "cellContent",
    key: "order",
    scopedSlots: { customRender: "order" },
    sorter: (a, b) =>
      a.NUMDOC.localeCompare(b.NUMDOC),
    sortDirections: ["descend", "ascend"],
    width: "33%",
    align: "center",
  },
  {
    title: "Date du document",
    dataIndex: "DOCVERSIONHUMAINE",
    key: "docVersion",
    scopedSlots: { customRender: "dateValidation" },
    sorter: (a, b) =>
      a.DOCVERSION.localeCompare(b.DOCVERSION),
    sortDirections: ["descend", "ascend"],
    defaultSortOrder: "descend",
    width: "33%",
    align: "center",
  },
  {
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "33%",
    align: "center",
  },
];

const columns2 = [
  {
    title: "Document",
    dataIndex: "DOCVERSION",
    key: "lib",
    scopedSlots: { customRender: "lib" },
    width: "50%",
    align: "center",
  },
  {
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "50%",
    align: "center",
  },
];

export default {
  data() {
    return {
      checkedList: null,
      columns: columns,
      columns2: columns2,
      docs: {},
      defaultListOrders: [],
      options: null,
      order: {},
      orderTitle: {},
      orders: [],
      searchInputValue: "",
      searchResults: null,
      visibleSpin: false,
      visibleModal: false,
      tempNumDoc: null,
    };
  },
  methods: {
    moment,
    cancel() { },
    closeModal() {
      this.visibleModal = false;
    },
    async downloadFileV2(doc) {
      const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
      const params = {
        token: token,
        uuid: doc.UUID,
        numDoc: doc.NUMDOC,
        typeDoc: doc.TYPE_DOC
      };
      await this.$store.dispatch("downloadDocV2", params);
    },
    onChange() {
      this.onSearch();
    },
    onSearch() {
      const searchValues = this.searchInputValue.trim().split("*");
      if (searchValues.length > 0 && searchValues.length > 0) {
        this.orders = this.defaultListOrders.filter((order) => {
          let result = false;
          if (this.checkedList !== null && this.checkedList.length > 0) {
            this.checkedList.forEach((el) => {
              if (el == order.TYPE_DOC) {
                result = true;
              }
            });
          } else {
            result = true;
          }
          if (result) {
            searchValues.forEach((value) => {
              if (
                order.NUMDOC.toUpperCase().includes(value.toUpperCase()) ||
                order.REFCDE.toUpperCase().includes(value.toUpperCase()) ||
                order.DOCVERSIONHUMAINE.toUpperCase().includes(value.toUpperCase())
              ) {
                if (value.trim() !== "") {
                  result = true;
                  return;
                }
              } else {
                result = false;
                return;
              }
            });
          }
          return result;
        });
        if (this.searchInputValue.length === 0) {
          this.searchResults = null;
        } else {
          this.searchResults = this.orders.length;
        }
      } else {
        this.orders = this.defaultListOrders;
        this.searchResults = 0;
      }
    },
    setCellContent(data) {
      const typeDoc = data.TYPE_DOC !== "" ? data.TYPE_DOC : "...";
      const ligne1 =
        '<div style="width:70%; margin: 0 auto; display:flex; border-bottom: 1px solid #ccc; height: calc(100% / 3)"><div style="width:60%; text-align: left;">N° Doc Thiriez Literie :</div><div style="width:40%; text-align: left;">'
        + data.NUMDOC + "</div></div>";
      const ligne2 =
        '<div style="width:70%; margin: 0 auto; display:flex; border-bottom: 1px solid #ccc; height: calc(100% / 3)"><div style="width:60%; text-align: left;">Type de document :</div><div style="width:40%;  text-align: left;">'
        + typeDoc + "</div></div>";
      const personalRef = data.REFCDE !== "" ? data.REFCDE : "aucune";
      const ligne3 =
        '<div style="width:70%; margin: 0 auto; display:flex; height: calc(100% / 3)"><div style="width:60%; text-align: left;">Votre référence :</div><div style="width:40%; text-align: left;">' +
        personalRef +
        "</div></div>";
      return ligne1 + ligne2 + ligne3;
    },
    showModal(order) {
      this.docs = order;
      this.tempNumDoc = order.NUMDOC;
      let tempTitle = order.REFCDE !== "" ? " - Ref : " + order.REFCDE : "";
      this.orderTitle = order.TYPE_DOC + " n° " + order.NUMDOC + tempTitle;
      this.visibleModal = true;
    },
    // showSAV(order) {
    //   this.$router.push(`/sav?order=${order.REFCDE}&invoice=388119`);
    // },
  },
  async created() {
    this.visibleSpin = true;
    let titleHeader = {
      title: "Documents",
      subTitle: "Vous avez ... documents",
      icon: '<i aria-label="icon: file-done" class="anticon anticon-file-done"><svg viewBox="64 64 896 896" data-icon="file-done" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M688 312v-48c0-4.4-3.6-8-8-8H296c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8zm-392 88c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H296zm376 116c-119.3 0-216 96.7-216 216s96.7 216 216 216 216-96.7 216-216-96.7-216-216-216zm107.5 323.5C750.8 868.2 712.6 884 672 884s-78.8-15.8-107.5-44.5C535.8 810.8 520 772.6 520 732s15.8-78.8 44.5-107.5C593.2 595.8 631.4 580 672 580s78.8 15.8 107.5 44.5C808.2 653.2 824 691.4 824 732s-15.8 78.8-44.5 107.5zM761 656h-44.3c-2.6 0-5 1.2-6.5 3.3l-63.5 87.8-23.1-31.9a7.92 7.92 0 0 0-6.5-3.3H573c-6.5 0-10.3 7.4-6.5 12.7l73.8 102.1c3.2 4.4 9.7 4.4 12.9 0l114.2-158c3.9-5.3.1-12.7-6.4-12.7zM440 852H208V148h560v344c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V108c0-17.7-14.3-32-32-32H168c-17.7 0-32 14.3-32 32v784c0 17.7 14.3 32 32 32h272c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8z"></path></svg></i>',
    };
    await this.$store.dispatch("setHeaderTitle", titleHeader);

    const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
    const docListV2 = await this.$store.dispatch("getOrderListV2", token);

    let docsLength = {
      AVOIR: 0,
      BL: 0,
      COMMANDE: 0,
      FACTURE: 0
    };

    let dataOrdersListV2 = [];
    if (docListV2 !== null) {
      dataOrdersListV2 = JSON.parse(localStorage.getItem("ordersListV2"));
    }
    if (dataOrdersListV2 !== null) {
      dataOrdersListV2.forEach((el) => {
        el.lib = "..."
        el.cellContent = "..."
        el.cellContent = this.setCellContent(el);
        if (el.TYPE_DOC == "AVOIR") {
          docsLength.AVOIR += 1;
          el.lib = "Avoir ";
        }
        if (el.TYPE_DOC == "BL") {
          docsLength.BL += 1;
          el.lib = "BL ";
        }
        if (el.TYPE_DOC == "COMMANDE") {
          docsLength.COMMANDE += 1;
          el.lib = "Commande ";
        }
        if (el.TYPE_DOC == "FACTURE") {
          docsLength.FACTURE += 1;
          el.lib = "Facture ";
        }
        el.lib = el.lib + "(" + moment(el.DOCVERSION).format("DD/MM/YY") + ")";
        el.DOCVERSIONHUMAINE = ("" + new Date(el.DOCVERSION).toLocaleDateString('fr-FR', { weekday: 'long', day: 'numeric', year: 'numeric', month: 'long' }));
        el.DOCVERSIONHUMAINE = el.DOCVERSIONHUMAINE.charAt(0).toUpperCase() + el.DOCVERSIONHUMAINE.slice(1)
      })
      this.options = Object.entries(docsLength).map(([key, value]) => {
        return { label: key + " (" + value + ")", value: key };
      });
      
      this.orders = this.defaultListOrders = dataOrdersListV2;

      if (this.orders.length == 1) {
        titleHeader.subTitle = "Vous avez 1 document";
      } else {
        titleHeader.subTitle = "Vous avez " + this.orders.length + " documents";
      }

      await this.$store.dispatch("setHeaderTitle", titleHeader);
      this.visibleSpin = false;
    }
    else {
      this.orders = this.defaultListOrders = [];
      titleHeader.subTitle = "Vous n'avez aucun document"
      await this.$store.dispatch("setHeaderTitle", titleHeader);
      this.visibleSpin = false;
    }
  },
};
</script>

<style scoped lang="scss">
.orderPage {
  // display: flex;
  // justify-content: center;
  height: 100%;
  background: white;
  padding: 2% 4%;

  .ant-spin-nested-loading {
    // width: 95%;
    padding: 1%;
  }

  &-datatable {
    width: 100%;
    // margin-top: 32px;
    margin-bottom: 128px;

    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      >a-button {
        width: 120px;
      }
    }
  }

  &-filters {
    padding: 10px;
    width: 256px;
    border: 1px solid #aaa;
    border-radius: 8px;

    &-all {
      margin-bottom: 15px;
    }

    &-list {
      width: 200px;
      display: flex;
      flex-direction: column;
    }
  }

  &-header {
    // display: flex;
    // position: fixed;
    top: 75px;
    left: 215px;
    right: 15px;
    z-index: 2;
    justify-content: space-between;
    padding-top: 50px;
    padding: 16px;
    background: white;
  }

  &-modal {
    &-comm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      textarea,
      button {
        margin-top: 15px;
        margin: auto;

      }

      button {
        width: 150px;
      }
    }

    &-gamme,
    &-lib {
      text-align: center;
      white-space: pre-line;
    }

    &-gamme {
      padding-bottom: 10px;
    }

    &-lib {
      padding-bottom: 25px;
    }

    &-price {
      height: 150px;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
    }

    &-sell {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-total {
      margin-top: 5px;
      font-weight: bold;
    }

    &-taxe {
      margin-bottom: 5px;
      color: #22844e;
      font-size: 10px;
    }

    &-tranche {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
      margin-top: 20px;
    }
  }

  &-search {
    width: 300px;
    margin: auto;
    display: flex;
    // align-items: center;
    // justify-content: center;

    &-result {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-height: 40px;
    }
  }
}
</style>