<template>
  <div class="div-CGV">
    <h2>Article 1 – Objet</h2>
    <p>
      Les présentes Conditions Générales de Vente ont pour objet de définir les
      termes et conditions auxquels THIRIEZ propose et vend ses produits à ses
      clients distributeurs (ci-après « Acheteur ») aux fins de revente au
      détail à des consommateurs finaux.
    </p>
    <h2>Article 2 – Application</h2>
    <p>
      Toute commande ou vente est soumise aux présentes Conditions Générales de
      Vente, que l’Acheteur déclare accepter sans réserve. Les présentes
      Conditions Générales de Vente prévalent sur les documents qui auraient
      éventuellement été communiqués par l’Acheteur, en ce compris notamment les
      conditions générales d’achat de l’Acheteur. Toute dérogation ou clause
      contraire desdits documents de l’Acheteur ne sera valable que si elle a
      été acceptée expressément et par écrit par notre société.
    </p>
    <h2>Article 3 – Commande</h2>
    <h3 class="article-h3">Article 3.1 – Validité des devis</h3>
    <p>
      Sauf conditions particulières émises dans l’offre de prix, les remises de
      prix faites par la société THIRIEZ sont valables six mois à compter de la
      date du devis.
    </p>
    <h3 class="article-h3">Article 3.2 – Confirmation</h3>
    <p>
      Toute commande passée par l’Acheteur directement ou par l’intermédiaire
      d’un de nos commerciaux n’engage THIRIEZ que si elle est acceptée par
      l’envoi e-mail d’un accusé de réception (AR de commande) de THIRIEZ
      accompagné des conditions générales de vente. L’acceptation de commande
      (AR de commande) peut au choix de THIRIEZ et en fonction des informations
      dont elle dispose s’agissant de la situation financière de l’Acheteur,
      être conditionnée par la couverture de l’Acheteur auprès de
      l’assurance-crédit. Toute première commande d’un nouvel Acheteur doit être
      accompagnée de la fiche d’ouverture de compte fournie par THIRIEZ dûment
      complétée et d’un RIB, afin de permettre une ouverture de compte. A
      défaut, elle pourra ne pas être prise en compte au choix de THIRIEZ.
    </p>
    <h3 class="article-h3">Article 3.3 – Modification- Annulation</h3>
    <p>
      Toute modification de commande doit obtenir l’accord de THIRIEZ. THIRIEZ
      adressera une offre complémentaire ou modificative indiquant les demandes
      nouvelles ou modifiées et le prix afférant à ces modifications. Toute
      annulation de commande ne sera prise en considération qu’après réception
      de l’accord écrit de THIRIEZ. L’annulation acceptée entraînera l’émission
      d’une facture d’indemnité de dédit correspondant à 10% TTC du montant
      total de la commande.
    </p>
    <h3 class="article-h3">Article 3.4 – Prix</h3>
    <p>
      Tous les prix s’entendent hors éco-participation et hors taxes, sans
      escompte, départ usine et frais de transport en sus. Toute création ou
      modification de contributions, taxes, impôts, droits de douane seront
      répercutés de plein droit. Les prix indiqués sont ceux basés sur les
      conditions économiques au jour de l’établissement du devis. Ils peuvent
      être révisés en cas d’augmentation des prix de revient de plus de 5%
      intervenant entre la commande et la livraison. Le prix facturé sera alors
      celui en vigueur au moment de la livraison. THIRIEZ pourra accorder des
      rabais remises ristournes à l’Acheteur, en fonction de critères
      quantitatifs ou qualitatifs.
    </p>
    <h2>Article 4 – Livraison</h2>
    <h3 class="article-h3">Article 4.1-Délai de livraison</h3>
    <p>
      Les délais de livraison indiqués sur l’AR de commande ne sont donnés qu’à
      titre indicatif et n’ont aucune valeur contractuelle : tout retard de
      livraison ne pourra entraîner l’annulation de la commande. La
      responsabilité de THIRIEZ ne pourra être engagée pour tout préjudice
      résultant de ce retard. Toute modification par l’Acheteur de la date de
      livraison d’une commande dans les 8 jours avant la date de livraison
      prévue initialement engendrera des frais de stockage à hauteur de 10€ le
      M3 par mois indivisible.
    </p>
    <h3 class="article-h3">Article 4.2 – Cas de force majeure</h3>
    <p>
      Les cas de force majeure et événements imprévisibles (tels que grève,
      avarie de ma- chine, difficultés d’approvisionnement, incendie…), ainsi
      que tout événement échappant au contrôle de THIRIEZ et venant entraver, en
      tout ou partie, soit la production soit la fourniture soit le transport
      des marchandises mentionnées dans la commande, sont réputées rendre la
      commande inexécutable. THIRIEZ tiendra l’Acheteur au courant en temps
      opportun, des événements énumérés ci-dessus : les quantités prêtes à être
      livrées au moment de l’évènement, doivent être acceptées par
      l’Acheteur.<br />
      La force majeure et le fait du prince donnent à notre société le droit de
      résilier totalement ou partiellement toute commande ou d’en suspendre
      l’exécution sans préavis, ni indemnité.
    </p>
    <h3 class="article-h3">Article 4.3 – Expéditions</h3>
    <p>
      La livraison se fait au choix de l’Acheteur soit par expédition de la
      marchandise au lieu de livraison convenu, soit par sa mise à disposition
      dans les usines THIRIEZ. Si l’Acheteur souhaite que la marchandise soit
      livrée chez son client final par THIRIEZ, alors l’Acheteur devra
      transmettre à THIRIEZ l’ensemble des informations permettant cette
      livraison (notamment nom et adresse de livraison du client final).<br />
      Toutes les marchandises voyagent aux risques et périls du destinataire,
      même lorsqu’elles sont vendues Franco. En cas d’avarie ou de manquant, il
      appartient au destinataire de formuler par écrit des réserves précises sur
      le bordereau du transporteur, puis de confirmer les défectuosités
      constatées par lettre recommandée avec accusé de réception, dans les 3
      jours suivants la livraison, à ce même transporteur, avec copie pour
      THIRIEZ. L’Acheteur s’engage à informer ses clients finaux de cette
      obligation dans le contrat qui les lient. Dans les hypothèses où elle
      n’assure pas le transport des marchandises, THIRIEZ n’est pas responsable
      des frais de remplacement dus à une avarie ou un vol en cours de
      transport.<br />
      L’Acheteur s’engage à vérifier l’accessibilité du lieu de livraison par
      gros porteurs (semi-remorque ou camion remorque) à la date de livraison
      prévue. En cas de manquement, la société THIRIEZ ne saurait être
      responsable de surcoûts de transports engendrés (re-livraisons), qui
      seront facturés à l’Acheteur.
    </p>
    <h2>Article 5 – Garantie</h2>
    <p>
      Pendant toute la durée de la garantie, THIREZ LITERIE s’engage à réparer
      ou à remplacer par un produit identique ou équivalent selon l’évolution de
      son offre, un produit reconnu défectueux du fait d’un vice de fabrication
      ou de matière, sous réserves des exclusions définies au point suivant.
      Cette réparation ou ce remplacement ne donne pas droit à une extension de
      la garantie originale.
    </p>
    <h3 class="article-h3">Article 5.1 – Conditions de garantie</h3>
    <p>
      THIRIEZ offre une garantie contractuelle complémentaire à la garantie
      légale sur certains de ces produits :
    </p>
    <p>
      – Les matelas (sauf ceux de la collectivité) sont garantis 5 ans.
      Possibilité d’extension à 7 ans sur certains articles si le matelas est
      associé à l’un des sommiers THIRIEZ préconisés sur la fiche technique.
    </p>
    <p>
      – Les sommiers fixes, à lattes souples, rigides, ressort ou électriques
      TPR sont garantis 5 ans si le nombre de pieds et la hauteur préconisés
      sont respectés. – Les composants électriques (moteurs, télécommandes…)
      sont garantis 3 ans. Pour les matelas, cette garantie implique une
      utilisation sur un sommier à lattes ou tapissier en bon état dont la
      vétusté ne dépasse pas 2 ans à compter de la date d’achat figurant sur la
      facture sommier. En aucun cas, la garantie ne couvre les détériorations
      pouvant provenir d’un usage anormal (exemple : matelas plié, matelas posé
      à même le sol, matelas non compatible à la relaxation associé à un sommier
      TPR…), d’un accident, d’un cas de force majeure, de retouches ou de
      transformations apportées aux produits, ou de l’usure normale de ces
      derniers. Sont également exclus de la garantie les produits présentant un
      défaut d’entretien, des salissures, tâches ou souillures, et/ou ayant été
      soumis à un taux d’humidité important. Le stockage des matelas comprimés
      et roulés ne doit pas excéder 3 mois. Au-delà, THIRIEZ ne pourra être tenu
      responsable des problèmes constatés (dimensionnel / affaissement de la
      mousse, …). Le cœur du matelas est garanti contre tout affaissement
      anormal. Le tissu de recouvrement, les poignées et tous les éléments dont
      la bonne tenue est liée aux conditions d’utilisation sont exclus de la
      garantie du matelas. Les coutures et les fermetures à glissière sont
      garanties 6 mois.
    </p>
    <h3 class="article-h3">Article 5.2 – Tolérances</h3>
    <p>
      La norme NF EN 1334 permet une tolérance de 2 cm en longueur et largeur,
      et de 1 cm en épaisseur par rapport aux côtes nominales de votre matelas
      ou sommier (exemple: un matelas 140x190x24 cm peut faire en réalité 138
      x188 x 23 cm). La densité des matelas en mousse polyuréthane s’exprime en
      valeur brute de coulée ; En net, cette densité est inférieure de l’ordre
      de 2 Kg, et est conforme à la Norme NF EN ISO 845.<br />
      L’échelle de fermeté indiquée sur nos fiches techniques est donnée à titre
      indicatif. La fermeté de nos matelas en mousse (kpa) admet une tolérance
      de +/- 15% selon la norme NF EN ISO 3386-1. Lors des premiers mois
      d’utilisation d’un matelas, il est normal de constater un léger tassement
      des matières de rembourrage qui n’affecte en rien son confort ou sa
      longévité. Cet effet sera limité en suivant les conseils d’utilisation. La
      garantie du matelas s’appliquera à partir d’une perte de hauteur nominale
      de l’ordre de 12%, mesurée avec une règle au point haut d’une surface
      piquée matelassée. Toutes nos caisses sommiers sont en Epicéa, essence de
      bois contenant des nœuds ⊘ 40 mm maximum au plus tous les 300 mm.
    </p>
    <h3 class="article-h3">Article 5.3 – Réclamation</h3>
    <p>
      L’Acheteur s’engage à recevoir et traiter l’ensemble des éventuelles
      réclamations des clients finaux auxquels il a fourni les produits. Les
      manquants à la livraison du destinataire (client final ou Acheteur) de
      produits THIRIEZ (matelas-sommier-caisse de pieds…) doivent faire l’objet
      d’une réclamation selon les modalités décrites à l’article 4-3 dans les 3
      jours de la livraison ; Passé ce délai, les produits re-livrés seront
      facturés. En cas de réclamation de la part d’un client final portant sur
      des produits THIRIEZ, l’Acheteur procèdera à un premier examen pour
      s’assurer qu’elle relève bien de la garantie légale ou contractuelle de
      THIRIEZ. Si c’est le cas, l’Acheteur adresse par email ou tout autre moyen
      dans les meilleurs délais, la fiche de demande SAV fournie par THIRIEZ
      dûment complétée, accompagnée de l’original de la facture (ou des
      factures) attestant de la date d’achat ou la commande et des photos du
      produit défectueux qui justifient le défaut constaté. A réception de ces
      éléments, THIRIEZ transmettra après analyse à l’Acheteur un courrier email
      d’acceptation ou de refus de la prise en charge du SAV. Toute demande de
      prise sous garantie pourra nécessiter la présentation de pièces
      complémentaires justifiant la non-conformité (autres photos, mesures,
      facture du sommier…).
    </p>
    <h3 class="article-h3">Article 5.4 – Traitement</h3>
    <p>
      Dans tous les cas de réclamation reconnue justifiée, THIRIEZ organise avec
      l’Acheteur la reprise de la marchandise. La garantie de THIRIEZ sera
      limitée au remplacement ou à la réparation de la marchandise, à
      l’exclusion de toute autre prestation ou indemnisation (dommages et
      intérêts…). Les produits défectueux doivent être retournés dans un
      emballage adapté et dans un état de propreté exempt de toutes souillures.
      Si, après examen du produit litigieux, THIRIEZ considérait finalement
      qu’il n’était pas couvert par la garantie, THIRIEZ facturera l’Acheteur du
      prix du produit de remplacement. En cas d’acceptation au titre de la
      garantie, THIRIEZ livrera à l’Acheteur un pro- duit de remplacement, qu’il
      appartiendra à l’Acheteur de livrer à son client final le cas échéant.
      Enfin, THIRIEZ facturera le produit de remplacement livré si l’Acheteur ne
      lui a pas retourné le produit défectueux réclamé pour examen.
    </p>
    <h2>Article 6 – Facturation</h2>
    <h3 class="article-h3">Article 6.1 – Clause de réserve de propriété</h3>
    <p>
      De convention entre les parties, les ventes de marchandises sont assorties
      de la clause de réserve de propriété (Loi N°80.335 du 12 Mai 1980) ; les
      marchandises livrées restent notre propriété exclusive jusqu’au paiement
      intégral du prix. Toutefois, les risques sont transférés dès la livraison.
      Ne constitue pas des paiements au sens de la présente clause, la remise de
      traites ou de tout autre titre créant une obligation de payer. L’Acheteur
      est autorisé dans le cadre de l’exploitation normale de son établissement,
      à revendre les marchandises livrées dans les conditions visées à l’article
      1 ; mais il ne peut ni les donner en gage ni en transférer la propriété à
      titre de garantie. Ils sont en outre insaisissables. En cas de revente,
      l’Acheteur s’engage soit à nous régler immédiatement la partie du prix
      restant due, soit à nous avertir immédiatement pour nous permettre
      d’exercer éventuellement notre droit de revendication sur le prix à
      l’égard du tiers acquéreur. L’autorisation de revente est retirée
      automatiquement et immédiatement en cas de cessation des paiements, ou
      retard de règlement de l’Acheteur.
    </p>
    <h3 class="article-h3">Article 6.2 – Paiement</h3>
    <p>
      Sauf conditions particulières convenues par écrit entre les parties, le
      paiement des marchandises s’effectue au comptant à réception de facture.
      Le paiement comptant d’une facture ne donnera lieu à aucun escompte pour
      paiement anticipé. De convention expresse et sauf report sollicité à temps
      et accordé par nous, le défaut de paiement de nos factures à l’échéance
      fixée entraînera :
    </p>
    <p>
      1- L’exigibilité d’un d’intérêt de retard contractuel égal à 3 fois le
      taux d’intérêt légal. En cas de retard de règlement, l’Acheteur devra
      s’acquitter des intérêts de retard calculés de la date d’échéance de la
      facture à la date du paiement effectif. Il devra en outre s’acquit- ter
      d’une pénalité de recouvrement forfaitaire s’élevant à 40 € (Art.
      L.441-6).
    </p>
    <p>
      2- L’exigibilité immédiate de toutes les sommes restant dues, quel que
      soit le mode de règlement prévu.
    </p>
    <p>
      3- En cas de recours judiciaire, l’exigibilité à titre de dommages et
      intérêts et de clause pénale, d’une indemnité égale à 15% des sommes dues
      outre les intérêts contractuels et les frais judiciaires éventuels.
    </p>
    <p>4- La possibilité d’annuler toutes les commandes en cours.</p>
    <h2>Article 7 – Conditions de revente sur internet</h2>
    <p>
      Les gammes de produits vendues par THIRIEZ sont différentes selon le canal
      de distribution par lequel l’Acheteur envisage de les revendre. Ainsi,
      THIRIEZ a élaboré une gamme dédiée de produits à la revente par internet.
      Seuls les produits de cette gamme peuvent être revendus par internet. Il
      appartient donc à l’Acheteur d’informer THIRIEZ de son canal de
      distribution et le cas échéant de commander les produits de la gamme y
      afférent.<br />
      Lorsque l’Acheteur revend les produits par internet, il s’engage à
      respecter les normes de qualité visées au sein de la Charte internet
      THIRIEZ, accessible à la suite des présentes Conditions Générales de
      Vente. A défaut de respecter ces normes, THIRIEZ pourra suspendre toute
      commande de l’Acheteur.
    </p>
    <h2>Article 8 – Propriété intellectuelle</h2>
    <p>
      Toute représentation, reproduction intégrale ou partielle de l’une des
      marques de THIRIEZ ou de documents notamment photographiques ou graphiques
      de ses produits, sous quelque forme que ce soit et par n’importe quel
      moyen, faite sans le consentement écrit de THIRIEZ, est illicite. Si elle
      est accordée, la représentation et la reproduction devra être faite dans
      le strict respect des conditions imposées par THIRIEZ.<br />
      A ce titre, l’Acheteur est autorisé à utiliser les marques, logos et
      dénominations des produits de THIRIEZ uniquement pour les besoins de la
      promotion et de la vente de ces produits. La reproduction de ces éléments
      devra être en tout point conforme à ceux transmis par THIRIEZ. En outre,
      THIRIEZ peut mettre à disposition de l’Acheteur des photographies,
      descriptifs et autre élément permettant la promotion et la vente des
      Produits. Ces éléments ne peuvent être utilisés par l’Acheteur que pour
      les seuls besoins de la promotion et de la vente des Produits. En outre,
      l’Acheteur s’engage à respecter les droits de propriété intellectuelle
      attachés à ces éléments et à ne les utiliser que pour les seuls besoins de
      leur revente aux clients finaux.
    </p>
    <h2>Article 9 – Résiliation</h2>
    <p>
      En cas de manquement de la part de l’Acheteur, toutes conventions et
      toutes commandes pourront être résiliées par THIRIEZ, en tout ou partie,
      de plein droit après mise en demeure donnée à l’Acheteur par lettre
      recommandée avec accusé de réception restée sans effet pendant un (1)
      mois, ou sans préavis en cas de faute grave rendant impossible la
      poursuite des relations, sans préjudice de tous dommages et intérêts que
      THIRIEZ pourrait réclamer.
    </p>
    <h2>Article 10 – Attribution de juridiction</h2>
    <p>
      De convention expresse, il est attribué compétence exclusive au tribunal
      de commerce de LILLE MÉTROPOLE, pour tous les litiges qui s’élèveraient
      entre les parties à l’occasion de leurs rapports commerciaux, quel que
      soit le lieu de livraison, le mode de paiement accepté, et même en cas
      d’appel en garantie ou pluralité de défendeurs.
    </p>
    <p>&nbsp;</p>
  </div>
</template>
                    
<script>
export default {};
</script>

<style>
</style>