import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeComponent from '../components/Home.vue'
import Products from '../components/Products.vue'
import CartStepper from '../components/CartStepper.vue'
import RecentOffers from '../components/Offers.vue'
import Orders from '../components/Orders.vue'
import Files from '../components/Files.vue'
// import SAV from '../components/SAV.vue'
import Profile from '../components/Profile.vue'
import Contact from '../components/Contact.vue'
const Background = () => import('../components/Background.vue')

import notificationManager from '../helpers/notificationManager'

Vue.use(VueRouter)

function handleTime(date, now) {
  var leftTime = date - now;
  if (leftTime < 60000) {
    return ((date - now) / 60000).toFixed(0) + " minutes et " + (((date - now) / 1000).toFixed(2) % 60).toFixed(0) + " secondes";
  }
  else {
    return ((date - now) / 60000).toFixed(0) + " minutes";
  }
}

function guardMyroute(to, from, next) {
  var isAuthenticated = false;
  if (sessionStorage.getItem('tokenInfos')) {
    const token = JSON.parse(sessionStorage.getItem('tokenInfos'))
    const date = new Date(token.EXPIRATION).getTime()
    const now = new Date().getTime()
    if (date - 10000 <= now) {
      isAuthenticated = false;
      notificationManager.openNotificationWithIcon('warning', 'Votre session a expiré', 'Veuillez vous reconnecter.')
    } else {
      isAuthenticated = true;
      if ((date - now) < 300000) {
        let timeDesc = handleTime(date, now);
        notificationManager.openNotificationWithIcon('warning', 'Votre session expire bientôt', 'Vous devrez vous reconnecter dans ' + timeDesc)
      }
    }
  } else {
    isAuthenticated = false;
    // notificationManager.openNotificationWithIcon('error', 'Vous n\'êtes pas connecté', 'Veuillez vous authentifier pour continuer')
  }

  if (isAuthenticated) {
    next(); // allow to enter route
  }
  else {
    next('/login'); // go to '/login';
  }
}

const routes = [
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import('../components/Welcome.vue')
  },
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    redirect: '/accueil',
    children: [
      {
        beforeEnter: guardMyroute,
        path: "accueil",
        name: "Home",
        component: HomeComponent,
      },
      {
        beforeEnter: guardMyroute,
        path: "produits",
        name: "Products",
        component: Products
      },
      {
        beforeEnter: guardMyroute,
        path: "panier",
        name: "Cart",
        component: CartStepper
      },
      {
        beforeEnter: guardMyroute,
        path: "paniers-saisis",
        name: "Offers",
        component: RecentOffers
      },
      {
        beforeEnter: guardMyroute,
        path: "documents",
        name: "Orders",
        component: Orders
      },
      {
        beforeEnter: guardMyroute,
        path: "fichiers",
        name: "Files",
        component: Files
      },
      // {
      //   beforeEnter: guardMyroute,
      //   path: "sav",
      //   name: "SAV",
      //   component: SAV
      // },
      {
        beforeEnter: guardMyroute,
        path: "profil",
        name: "Profile",
        component: Profile
      },
      {
        beforeEnter: guardMyroute,
        path: 'contact',
        name: 'Contact',
        component: Contact
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    components: {
      default: () => import('../views/Login.vue'),
      background: Background,
    }
  },
  {
    path: '/creation-de-compte',
    name: 'CreateAccount',
    components: {
      default: () => import('../components/CreateAccount.vue'),
      background: Background,
    }
  },
  {
    path: '/mot-de-passe-oublie',
    name: 'ForgotPassword',
    components: {
      default: () => import('../components/ForgotPassword.vue'),
      background: Background,
    }
  },
  {
    path: '/verification-par-mail',
    name: 'SendMailAccount',
    component: () => import('../components/SendMailAccount.vue'),
    props: true
  },
  {
    // will match everything
    path: '/:catchAll(.*)',
    name: "NotFound",
    component: () => import('../components/NotFound.vue'),
  }
]

const router = new VueRouter({
  // mode: 'history', // oblige à configurer IIS, sinon erreur 404
  mode: 'hash', // contourner l'erreur de rechargement de IIS mais ajoute un # dièse dans l'URL
  base: process.env.BASE_URL,
  routes: routes
})

export default router
