import { instance } from '../api.config'
import { HEADERS, ENDPOINTS, API } from "../constants.js"

export default {
  async getListBL(token, body) {
    // body = {
    //   "ACTION":"RETOUR_INFOS",
    //     "CODE_CLIENT":"101028",
    //     "NUMDOC" : "388119",
    //     "TYPEDOC": "FACTURE"
    // }
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'API': HEADERS.sav
      }
    }
    try {
      const res = await instance.post(API.baseUrl + ENDPOINTS.b2b, body, config)
      return res;
    } catch (e) {
      return e;
    }
  },
  async gedV2ListeDocuments(token) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'API': HEADERS.gedV2DocListe
      }
    }
    try {
      const res = await instance.post(API.baseUrl + ENDPOINTS.b2b, {}, config)
      return res;
    } catch (e) {
      return e;
    }
  },

  async getAllDocuments(token, body) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'API': HEADERS.gedListe
      }
    }
    try {
      const res = await instance.post(API.baseUrl + ENDPOINTS.b2b, body, config)
      return res;
    } catch (e) {
      return e;
    }
  },

  async downloadDocument(token, body) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'API': HEADERS.gedDocDl
      },
      responseType: 'arraybuffer',
    }
    try {
      const res = await instance.post(API.baseUrl + ENDPOINTS.b2b, body, config)
      return res;
    } catch (e) {
      return e;
    }
  },

  async downloadDocumentV2(token, body) {
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'API': HEADERS.downloadDocV2
      },
      responseType: 'arraybuffer',
    }
    try {
      const res = await instance.post(API.baseUrl + ENDPOINTS.b2b, body, config)
      return res;
    } catch (e) {
      return e;
    }
  }
}