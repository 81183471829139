<template>
  <div class="cartPage">
    <a-spin tip="Chargement..." size="large" :spinning="visibleSpin">
      <h1 v-if="visibleSpin">

      </h1>
      <a-row v-else>
        <h1 v-if="this.linesCartInfos.length == 0">
          Pas de panier en cours
        </h1>
        <div v-else>
          <!-- <div style="background: #ececec;margin: auto 8px;padding: 8px;height: 250px;">
            </div> -->
          <div v-if="showPrice" class="card-total">
            <div style="display: flex;">
              <div class="card-total-left cardh3">
                Articles:
              </div>
              <div class="card-total-right cardh3">
                {{ parseFloat(totalPanier).toFixed(3) }} €
              </div>
            </div>
            <div style="display:flex; border-bottom: 2px solid #ccc;">
              <div class="card-total-left cardh3" style="color: green">
                Écotaxe:
              </div>
              <div class="card-total-right cardh3" style="color: green">
                {{ parseFloat(totalEcotaxe).toFixed(3) }} €
              </div>
            </div>
            <div style="display:flex;">
              <div class="card-total-left cardh2">
                Total:
              </div>
              <div class="card-total-right cardh2">
                {{ parseFloat((totalPanier + totalEcotaxe)).toFixed(3) }} €
              </div>
            </div>
          </div>
          <p v-if="messageQuantity != ''" class="messageQte">{{ messageQuantity }}</p>
          <p v-if="messageDate != ''" class="warning-deliveryDate"> {{ messageDate }} </p>

          <a-table class="cartPage-datatable" rowKey="id" :columns="columns" :data-source="linesCartInfos" bordered
            :locale="{ emptyText: 'Aucun article disponible' }">
            <span slot="lib" slot-scope="text" v-html="text"></span>
            <span slot="quantity" slot-scope="text" v-html="text"></span>
            <span v-if="showPrice" class="cartPage-datatable-price" slot="price" slot-scope="text" v-html="text"></span>
            <span v-if="showPrice" class="cartPage-datatable-total" slot="totalPrice" slot-scope="text"
              v-html="text"></span>
            <span slot="deliveryDate" slot-scope="text" v-html="text" style="font-weight: bold"></span>
            <span slot="action" slot-scope="record">
              <i aria-label="icon: edit" class="anticon anticon-edit clickable-content" @click="showModal(record)">
                <svg viewBox="64 64 896 896" data-icon="edit" width="25px" height="25px" fill="currentColor"
                  aria-hidden="true" focusable="false" class>
                  <path
                    d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z" />
                </svg>
              </i>
              <a-popconfirm class="clickable-content" title="Supprimer?" ok-text="Oui" cancel-text="Non"
                @confirm="confirm(record)" @cancel="cancel">
                <i aria-label="icon: delete" class="anticon anticon-delete" style="margin-left: 15px">
                  <svg viewBox="64 64 896 896" data-icon="delete" width="25px" height="25px" fill="currentColor"
                    aria-hidden="true" focusable="false" class>
                    <path
                      d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8 8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                  </svg>
                </i>
              </a-popconfirm>
            </span>
          </a-table>
          <!-- <a-col :xs="24" :sm="24" :md="6" :lg="6" :xl="6">
            <div style="background: #ececec;margin: auto 8px;padding: 16px;height: 250px;">
              <a-card title="Total" style="max-width: 100%; height: 100%; text-align: center">
                <h3>{{ parseFloat(totalPanier.toFixed(3)) }} €</h3>
                <h4 style="color: green">
                  + écotaxe
                  {{ parseFloat(totalEcotaxe.toFixed(3)) }} €
                </h4>
                <h2>
                  = {{ parseFloat((totalPanier + totalEcotaxe).toFixed(3)) }} €
                </h2>
              </a-card>
              <br />
            </div>
          </a-col> -->
        </div>
      </a-row>
    </a-spin>
    <a-modal class="cartPage-modal" centered v-if="visibleModal" v-model="visibleModal" :footer="null"
      @cancel="closeModal(article)">
      <h2 class="cartPage-modal-gamme" :style="[
        { borderBottom: '5px solid ' + article.color, margin: '15px auto' },
      ]">
        {{ article.gamme.lib.toUpperCase() }}
      </h2>
      <h3 class="cartPage-modal-lib">{{ article.libModal }}</h3>
      <div v-if="showPrice" class="cartPage-modal-price">
        <div class="cartPage-modal-tranche">
          <h4 style="align-self: center; margin-bottom: 5px">TARIF :</h4>
          <span v-if="!article.remise.TRANCHE || article.remise.TRANCHE.length === 0
            ">Prix unitaire : {{ article.puRemise }} €</span>
        </div>
        <div class="cartPage-modal-sell">
          <a-input-number id="inputNumber" v-model="productQuantity" :min="1" :max="1000"
            @change="InputQuantityChange(article)" />
          <span class="products-modal-total">Total : {{ totalProduct.toFixed(2) }} €</span>
          <span class="cartPage-modal-taxe">+ écotaxe : {{ totalEcoTaxe.toFixed(2) }} €</span>
        </div>
      </div>
      <div v-else class="cartPage-modal-alt-mode">
        <h4 style="margin-right: 5px;">QUANTITÉ :</h4>
        <a-input-number style="margin-left: 5px;" id="inputNumber" v-model="productQuantity" :min="1" :max="1000"
          @change="InputQuantityChange(article)" />
      </div>
      <div class="cartPage-modal-comm">
        <a-textarea placeholder="Ajouter un commentaire (facultatif). Exemple: CM: Dupont" :maxLength="30" :rows="2"
          v-model="comArticle" />
        <div>{{ comArticle.length }} / 30</div>
        <a-button :disabled="productQuantity == article.quantity && comArticle == article.com" type="primary"
          @click="updateProduct(article, productQuantity, $event)">Modifier l'article
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";

const columns = [
  {
    title: "Libellés",
    dataIndex: "lib",
    key: "lib",
    scopedSlots: { customRender: "lib" },
    sorter: (a, b) => a.lib.localeCompare(b.lib),
    sortDirections: ["ascend", "descend"],
    width: "35%",
    align: "center",
  },
  {
    title: "Qté.",
    dataIndex: "quantity",
    key: "quantity",
    scopedSlots: { customRender: "quantity" },
    sorter: (a, b) => a.quantity - b.quantity,
    sortDirections: ["ascend", "descend"],
    width: "auto",
    align: "center",
  },
  {
    title: "Prix unitaire",
    key: "price",
    dataIndex: "price",
    scopedSlots: { customRender: "price" },
    sorter: (a, b) => (a.pu !== b.pu ? (a.pu < b.pu ? -1 : 1) : 0),
    sortDirections: ["ascend", "descend"],
    width: "14%",
    align: "center",
  },
  {
    title: "Total ligne",
    key: "totalPrice",
    dataIndex: "totalPrice",
    scopedSlots: { customRender: "totalPrice" },
    sorter: (a, b) => (a.pu * a.quantity !== b.pu * b.quantity ? (a.pu * a.quantity < b.pu * b.quantity ? -1 : 1) : 0),
    sortDirections: ["descend", "ascend"],
    width: "14%",
    align: "center",
  },
  {
    title: "Départ estimé",
    key: "deliveryDate",
    dataIndex: "deliveryDate",
    scopedSlots: { customRender: "deliveryDate" },
    sorter: (a, b) => a.valueDate - b.valueDate,
    sortDirections: ["ascend", "descend"],
    width: "14%",
    align: "center",
  },
  {
    title: "",
    key: "action",
    scopedSlots: { customRender: "action" },
    width: "auto",
    align: "center",
  },
];

export default {
  data() {
    return {
      columns: null,
      linesCartInfos: [],
      visibleSpin: true,
      visibleModSpin: false,
      visibleModal: false,
      gammes: [],
      comArticle: "",
      article: null,
      productQuantity: 1,
      rawListArticles: [],
      totalEcotaxe: 0,
      totalPanier: 0,
      totalProduct: 0,
      totalEcoTaxe: 0,
      message1: "Attention, pour les quantités de 5 ou plus, les dates sont à titre indicatif et pourront être ajustées par le service commercial.",
      message2: "En cas de délai trop long, n'hésitez pas à contacter votre ADV pour trouver une solution.",
      messageQuantity: "",
      messageDate: "",
      showPrice: false,
    };
  },
  computed: {
    ...mapState(["cartStatus", "linesCart", "linesCartLength"]),
  },
  methods: {
    addDateToLine() {
      this.linesCartInfos.forEach((line) => {
        let result = this.setDateProduct(line.codeArticle)
        line.deliveryDate = result.stringDate
        line.valueDate = result.valueDate
      })
    },
    confirm(article) {
      this.deleteProduct(article);
    },
    cancel() { },
    showModal(article) {
      this.article = article;
      this.visibleModal = true;
      this.productQuantity = article.quantity;
      this.totalProduct = Number(article.puRemise) * article.quantity;
      this.totalEcoTaxe = article.ecoTaxe;
      this.comArticle = article.com;
    },
    InputQuantityChange(product) {
      this.totalProduct = this.productQuantity * product.puRemise;
      this.totalEcoTaxe = this.productQuantity * product.ecoTaxe;
    },
    closeModal(article) {
      this.visibleModal = false;
      this.productQuantity = article.quantity;
    },
    countQte(arr, key) {
      let arr2 = [];
      arr.forEach((x) => {
        if (
          arr2.some((val) => {
            return val[key] == x[key];
          })
        ) {
          arr2.forEach((k) => {
            if (k[key] === x[key]) {
              k["QTE"] += x["QTE"];
            }
          });
        } else {
          let a = {};
          a[key] = x[key];
          a["QTE"] = x["QTE"];
          arr2.push(a);
        }
      });

      return arr2;
    },
    async deleteProduct(article) {
      let cartId = JSON.parse(localStorage.getItem("cartInfos")).PANIER_ID;
      const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
      let params = {
        token: token,
        id: cartId,
      };
      const entete = await this.$store.dispatch("getEnteteCart", params);
      if (entete.code === 0) {
        if (!entete.CLOTURE) {
          let paramsDelete = {
            token: token,
            cartId: cartId,
            lineId: article.id,
          };
          this.rawListArticles = await this.$store.dispatch("getLinesCart", params);
          if (this.rawListArticles && this.rawListArticles.length !== 0) {
            if (this.rawListArticles.length == 1) {
              this.visibleSpin = true;
              this.rawListArticles = []
              await this.$store.dispatch("deleteLineCart", paramsDelete)
              this.getLines(params);
              this.visibleSpin = false;
              this.$router.push("/produits");
            } else {
              await this.$store
                .dispatch("deleteLineCart", paramsDelete)
                .then(async (res) => {
                  this.visibleSpin = false
                  if (res === true) {
                    let cartInfos = JSON.parse(
                      localStorage.getItem("cartInfos")
                    ).PANIER_ID;
                    if (cartInfos) {
                      this.rawListArticles = await this.$store.dispatch("getLinesCart", params);
                      this.$emit("updateTitle", this.rawListArticles.length);
                      if (this.rawListArticles.length > 0) {
                        this.getLines(params);
                        this.getSummaries();

                        await this.estimateDelay();
                        this.addDateToLine()
                      } else {
                        this.$router.push("/produits");
                      }
                    } else {
                      this.$router.push("/produits");
                    }
                  }
                });
            }
          }
        } else {
          this.linesCartInfos = [];
          localStorage.removeItem("cartInfos");
          localStorage.removeItem("linesCartInfos");
          this.$router.push("/produits");
        }
      } else {
        this.$router.push("/produits");
      }
    },
    async estimateDelay() {
      const token = JSON.parse(sessionStorage.getItem("tokenInfos")).TOKEN;
      var ARTICLES = [];
      let index = 0;
      this.rawListArticles.forEach(
        (article) =>
        (ARTICLES[index++] = {
          CODE_ARTICLE: article.CODE_ARTICLE,
          QTE: article.QTE,
        })
      );
      ARTICLES = this.countQte(ARTICLES, "CODE_ARTICLE");
      const params = { token: token, body: ARTICLES };
      await this.$store.dispatch("getEstimerDelai", params);
      const listDeliveryDates = JSON.parse(
        localStorage.getItem("estimerDelai")
      );
      this.listeDatesLivraison = listDeliveryDates;
      return;
    },
    async getLines() {
      this.linesCartInfos = this.rawListArticles
        .map((data) => {
          4;
          const pubrut = data.PU;
          let remise = data.REMISE.RH;
          let remiseTotale = "";
          let price = (Math.round(100 * pubrut * (1 + remise / 100)) / 100).toFixed(2);
          if (data.REMISE.INVISIBLE) {
            remise = " €";
          } else {
            if (data.REMISE.RH !== 0) {
              remise =
                ' €<br /><span style="font-size: 14px;">(' +
                data.PU.toFixed(2) +
                "€ " +
                data.REMISE.RH +
                "%)</span>";
              remiseTotale = " €"
            } else {
              remise = " €";
              remiseTotale = " €";
            }
          }
          let libModal = "";
          if (
            data.REF_CLIENT.CODE_ARTICLE !== "" &&
            data.REF_CLIENT.LIB01 !== ""
          ) {
            libModal =
              data.REF_CLIENT.CODE_ARTICLE +
              "\n" +
              data.REF_CLIENT.LIB01 +
              "\n" +
              data.REF_CLIENT.LIB02;
          } else {
            libModal = data.LIB01 + "\n" + data.LIB02 + "\n" + data.LIB03;
          }
          let res = {
            codeArticle: data.CODE_ARTICLE,
            id: data.LIGNE_ID,
            gamme: this.setLibGamme(data.IDGAMME),
            color: this.setColorGamme(data.IDGAMME),
            // stock: this.setStock(data.EN_STOCK),
            lib: this.setLibProduct(data),
            libModal: libModal,
            pu: data.PU,
            ecoTaxe: data.ECOTAXE.PRIX,
            puRemise: price,
            totalPrice: (parseFloat(price) * data.QTE).toFixed(2)
              + remiseTotale
              + '<br /><span style="color: #22844e; font-size: 14px;">+ écotaxe: '
              + data.ECOTAXE.PRIX * data.QTE + " €</span>",
            price:
              price
              + remise
              + '<br /><span style="color: #22844e; font-size: 14px;">+ écotaxe: '
              + data.ECOTAXE.PRIX
              + " €</span>",
            remise: data.REMISE,
            quantity: data.QTE,
            com: data.COMM,
            deliveryDate: "...",
            valueDate: new Date().getTime()
          };
          return res;
        });
    },
    getSummaries() {
      let totalCart = 0;
      let totalTax = 0;
      try {
        const strLines = localStorage.getItem("linesCartInfos");
        if (strLines) {
          // const linesCartInfos = JSON.parse(localStorage.getItem("linesCartInfos"));
          this.messageQuantity = ""
          this.linesCartInfos.forEach((line) => {
            totalCart += parseFloat(line.puRemise) * parseFloat(line.quantity);
            totalTax += parseFloat(line.ecoTaxe) * parseFloat(line.quantity);
            if (line.quantity >= 5) (this.messageQuantity = this.message1)
          });
        }
      } catch (error) {
        console.log(error);
      }
      this.totalPanier = totalCart;
      this.totalEcotaxe = totalTax;
    },
    setColorGamme(id) {
      let res;
      this.gammes.forEach((el) => {
        if (id == el.id) {
          res = el.color;
          return;
        }
      });
      return res;
    },
    setDateProduct(codeArt) {
      let valueDate = new Date().getTime()
      let delivDate = "...";
      let messageDate = "";
      if (this.listeDatesLivraison) {
        this.listeDatesLivraison.forEach((x) => {
          if (codeArt == x["CODE_ARTICLE"]) {
            let d1 = new Date(x["DATE_LIVRAISON"]);
            valueDate = d1.getTime()
            if (d1 <= Date.now() + 3600000 * 24 * 7 * 4) { // moins de 4 semaines, OK, vert
              delivDate = '<span style="color:#32be64">' +
                d1.toLocaleDateString() + "</span>";
            } else {
              messageDate = this.message2;
              if (d1 > Date.now() + 3600000 * 24 * 7 * 6) { // plus de 6 semaines, Rouge
                delivDate = '<span style="color:#ff0000">' +
                  d1.toLocaleDateString() + "</span>";
              } else { // entre 4 et 6 semaines, Orange
                delivDate = '<span style="color:#ff7f00">' +
                  d1.toLocaleDateString() + "</span>";
              }
            }
          }
        });
      }
      this.messageDate = messageDate
      return { valueDate: valueDate, stringDate: delivDate };
    },
    setLibGamme(id) {
      let res;
      this.gammes.forEach((el) => {
        if (id == el.id) {
          res = {
            lib: el.lib,
            color: el.color,
          };
          return;
        }
      });
      return res;
    },
    setLibProduct(product) {
      let res = "";
      if (
        product.REF_CLIENT.CODE_ARTICLE !== "" &&
        product.REF_CLIENT.LIB01 !== ""
      ) {
        res =
          '<span style="font-weight: bolder;">' +
          product.REF_CLIENT.CODE_ARTICLE +
          "</span> <br> <span> " +
          product.REF_CLIENT.LIB01 +
          " " +
          product.REF_CLIENT.LIB02 +
          "</span>";
      } else {
        res =
          '<span style="font-weight: bolder;">' +
          product.LIB01 +
          "</span> <br> <span> " +
          product.LIB02 +
          " " +
          product.LIB03 +
          "</span>";
      }
      const comm = product.COMM
        ? "<br /><span>Commentaire : " + product.COMM + "</span>"
        : "";
      return res + comm;
    },
    // setStock(data) {
    //   return data
    //     ? '<span style="width:12px; height:10px; background:#22844e">&nbsp;&nbsp;&nbsp;&nbsp;</span><span>&nbsp;&nbsp;En stock</span>'
    //     : '<span style="width:12px; height:10px; background:#ff7f00">&nbsp;&nbsp;&nbsp;&nbsp;</span><span>&nbsp;&nbsp;En fabrication</span>';
    // },
    async updateProduct(article, quantity, event) {
      event.target.disabled = true;
      this.visibleModal = false;
      let cartId = JSON.parse(localStorage.getItem("cartInfos")).PANIER_ID;
      const tokenInfos = JSON.parse(sessionStorage.getItem("tokenInfos"));
      if (tokenInfos) {
        const token = tokenInfos.TOKEN;
        const params = {
          token: token,
          id: cartId,
        };
        const entete = await this.$store.dispatch("getEnteteCart", params);
        if (entete.code === 0) {
          if (!entete.data.CLOTURE) {
            let paramsUpdate = {
              token: token,
              article: article,
              quantity: quantity,
            };
            if (this.comArticle !== null || this.comArticle !== "") {
              paramsUpdate.article.com = this.comArticle;
            }
            this.visibleModSpin = true
            await this.$store
              .dispatch("updateLineCart", paramsUpdate)
              .then(async (res) => {
                if (res === true) {
                  cartId = JSON.parse(
                    localStorage.getItem("cartInfos")
                  ).PANIER_ID;
                  this.rawListArticles = await this.$store.dispatch("getLinesCart", params);
                  this.$emit("updateTitle", this.rawListArticles.length);
                  this.getLines();
                  this.getSummaries();
                  this.comArticle = "";
                } else {
                  event.target.disabled = false;
                }
              });
            this.visibleModSpin = false
            if (this.rawListArticles && this.rawListArticles.length > 0) {
              await this.estimateDelay();
              this.addDateToLine()
            }
          } else {
            this.$emit("updateTitle", 0);
            this.linesCartInfos = [];
            localStorage.removeItem("cartInfos");
            localStorage.removeItem("linesCartInfos");
            this.$router.push("/produits");
          }
        } else {
          this.$emit("updateTitle", 0);
          this.$router.push("/produits");
        }
      } else {
        this.$router.push("/login");
      }
    },
    async playMainSequence() {
      const tokenInfos = JSON.parse(sessionStorage.getItem("tokenInfos"));
      if (tokenInfos) {
        const token = tokenInfos.TOKEN;
        const cart = localStorage.getItem("cartInfos");
        //console.log('cart :'+cart)
        if (cart) {
          const params = {
            token: token,
            id: JSON.parse(cart).PANIER_ID,
          };
          const entete = await this.$store.dispatch("getEnteteCart", params);
          if (entete.code === 0) {
            this.visibleSpin = true;
            if (!entete.CLOTURE) {
              this.gammes = JSON.parse(localStorage.getItem("gammesInfos"));
              this.rawListArticles = await this.$store.dispatch("getLinesCart", params);
              this.$emit("updateTitle", this.rawListArticles.length);

              if (this.rawListArticles && this.rawListArticles.length > 0) {
                this.getLines(params);
                this.getSummaries();
              }
            } else {
              this.linesCartInfos = [];
              this.$emit("updateTitle", 0);
            }
            this.visibleSpin = false;
            if (this.rawListArticles && this.rawListArticles.length > 0) {
              await this.estimateDelay();
              this.addDateToLine()
            }
          } else {
            this.visibleSpin = false;
            this.linesCartInfos = [];
            this.$emit("updateTitle", 0);
          }
        } else {
          this.visibleSpin = false;
          this.linesCartInfos = [];
          this.$emit("updateTitle", 0);
        }
      }
    }
  },
  async mounted() {
    this.columns = columns.filter(el => (el.key != ("totalPrice" && "price")))
    const tmpShowPrice = JSON.parse(localStorage.getItem("showPrice"));
    if (tmpShowPrice == null) {
      localStorage.setItem('showPrice', JSON.stringify(true))
      this.showPrice = true
      this.columns = columns
    } else {
      this.showPrice = tmpShowPrice
      this.columns = columns.filter(el => (el.key != "price" && el.key != "totalPrice" || this.showPrice))
    }
    await this.playMainSequence()
  },
};
</script>

<style scoped lang="scss">
.cartPage {
  // display: flex;
  justify-content: center;
  // height: 100%;
  background: white;
  padding: 2% 4%;

  overflow-x: auto;

  &-datatable {
    min-width: 500px;

    margin: 1% auto 8% auto;
    // margin-bottom: 64px;
    width: 100%;

    &-price,
    &-total {
      font-size: 16px;
    }
  }


  &-modal {
    &-comm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      textarea,
      button {
        margin-top: 15px;
      }

      button {
        width: 50%;
      }
    }

    &-gamme,
    &-lib {
      text-align: center;
      white-space: pre-line;
    }

    &-gamme {
      padding-bottom: 10px;
    }

    &-lib {
      padding-bottom: 25px;
    }

    &-price {
      height: 150px;
      border: 1px solid #ccc;
      border-radius: 5px;
      display: flex;
    }

    &-sell {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &-total {
      margin-top: 5px;
      font-weight: bold;
    }

    &-taxe {
      margin-bottom: 5px;
      color: #22844e;
      font-size: 10px;
    }

    &-tranche {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
      margin-top: 20px;
    }

    &-alt-mode {
      width: 100%;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
    }
  }
}

.clickable-content {
  cursor: pointer;
}

.card-total {
  align-self: center;
  width: 90%;
  max-width: 300px;
  background: #ffffff;
  border-color: #000000;
  border: solid 2px;
  border-radius: 16px;
  margin: auto;
  margin-bottom: 20px;
  padding: 8px;

  >.div {
    padding: 8px;
  }

  &-left {
    width: 40%;
    text-align: left;
  }

  &-right {
    width: 60%;
    text-align: right;
  }

  .cardh2 {
    font-size: 1.5em;
    font-weight: 700;
  }

  .cardh3 {
    font-size: 1.17em;
    font-weight: 600;
  }
}

.messageQte {
  margin: auto;
  width: fit-content;
  min-width: 300px;
  max-width: 550px;
  color: #ff7f00;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  border: 2px solid #ff7f00;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}

.warning-deliveryDate {
  margin: auto;
  width: fit-content;
  min-width: 300px;
  max-width: 550px;
  color: #ff7f00;
  font-size: 16px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
  border: 2px solid #ff7f00;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 8px;
}
</style>